// Packages:
import { useState } from 'react'

// Components:
import TopBar from '../../components/TopBar'
import DataViz from './DataViz'
import Insights from './Insights'


// Functions:
const Dashboard = () => {

  const [graphUrl, setGraphUrl] = useState<string>("total_volume_assignee");

  // Return:
  return (
    <div>
      <TopBar />
      <div className="main-content-container">
        <span className="main-content-panel">
          <DataViz
            graphUrl={graphUrl}
          />
        </span>
        <Insights
          setGraphUrl={ setGraphUrl }
        />
      </div>
    </div>
    
  )
}


// Exports:
export default Dashboard
