// Packages:
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import axios from 'axios';

import { Scrollbars } from "react-custom-scrollbars";

import styled from 'styled-components'
import { fadeIn } from '../../../styles/animations'

import { Button, ButtonGroup, Spinner } from 'react-bootstrap';
import { AiOutlineBell } from 'react-icons/ai';

import { getCurrenOrgID } from '../../../redux/slices/orgInfoSlice';
import { getLoggedInUser } from '../../../redux/slices/authSlice';

import SubscriptionModal from './SubscriptionModal';

import { EMER_TOPICS_INSIGHTS, EMER_TOPICS_SUB_STATUS } from '../../../Endpoints';

import {
  AxiosHeadersInterface,
  EmerInsightInterface,
  WithIndex
} from '../../../constants/interfaces';


const Title = styled.div<{ transitionFactor: number }>`
  margin-bottom: 0.5rem;
  padding: 0 1rem;
  font-weight: 700;
  font-size: 1.3rem;
  animation : ${ props => fadeIn(props.transitionFactor + 3) } ${ props => 0.25 * props.transitionFactor }s ease;
  user-select: none;
`

const SubTitle = styled.div<{ transitionFactor: number }>`
  margin-top: 0.25rem;
  font-weight: 400;
  font-size: 0.9rem;
  text-align: left;
  animation : ${ props => fadeIn(props.transitionFactor + 3) } ${ props => 0.25 * props.transitionFactor }s ease;
`


// Functions:
const EmergingTopicInsights = ({
  handleInsightClick,
  selectedInsightID
} : {
  handleInsightClick: (insightData: EmerInsightInterface) => void
  selectedInsightID: number
}) => {

  const currOrgID = useSelector(getCurrenOrgID);
  const logInStatus = useSelector(getLoggedInUser);

  const [insightDurationType, setInsightDurationType] = useState<string>('all');

  const [emerInsights, setEmerInsights] = useState<EmerInsightInterface[]>([]);

  const [modalShow, setModalShow] = useState<boolean>(false);

  const [isSubscribed, setIsSubscribed] = useState<boolean>(false);
  const [subscriptionInfo, setSubscriptionInfo] = useState<any>([]);

  const getInsightList = useCallback(async () => {
    try {
      setEmerInsights([]);

      const reqdate = new Date();
      const currDate = reqdate.getFullYear() + '-' +
                        (reqdate.getMonth() + 1) + '-' +
                        reqdate.getDate();

      const reqHeaders: WithIndex<AxiosHeadersInterface> = {}
      reqHeaders['X-API-Key'] = process.env.REACT_APP_API_KEY
      const res = await axios.get(EMER_TOPICS_INSIGHTS, {
        params: {
          org_id: currOrgID,
          date: currDate,
        },
        headers: reqHeaders,
      });
      setEmerInsights(res.data);

    } catch (error) {
      console.error(error);
    }
  }, [currOrgID]);

  useEffect(() => {
    getInsightList();
  }, [getInsightList]);


  const checkSubscriptionStatus = async () => {
    try {
      const reqHeaders: WithIndex<AxiosHeadersInterface> = {}
      reqHeaders['X-API-Key'] = process.env.REACT_APP_API_KEY

      const res = await axios.get(EMER_TOPICS_SUB_STATUS, {
        params: {
          org_id: currOrgID,
          email: logInStatus.attributes.email,
        },
        headers: reqHeaders,
      });

      setIsSubscribed(res.data.Subscription_status)
      setSubscriptionInfo(res.data.Details)

    } catch (error) {
      console.error(error);
    }
  }


  const openSubscriptionModal = async () => {
    // Check subscription status.
    await checkSubscriptionStatus()
    // Open the modal.
    setModalShow(true)
  }


  // Return:
  return (
    <div id="emerging-topic-insight-bar">
      <Scrollbars autoHide>
        <Title transitionFactor={ 1 }>
          <span>Emerging Topics</span>
          <span style={{ float: "right" }}>
            <AiOutlineBell
              style={{cursor: "pointer"}}
              onClick={openSubscriptionModal}
            />
          </span>
        </Title>
        <div style={{ margin: "1rem 0"}}>
          <ButtonGroup aria-label="Anomaly Type Toggle" size="sm" >
            <Button
              onClick={() => setInsightDurationType('all')}
              variant="secondary"
              active={insightDurationType === 'all'}
            >
              All
            </Button>
            <Button
              onClick={() => setInsightDurationType('hourly')}
              variant="secondary"
              active={insightDurationType === 'hourly'}
            >
              Hourly
            </Button>
            <Button
              onClick={() => setInsightDurationType('daily')}
              variant="secondary"
              active={insightDurationType === 'daily'}
            >
              Daily
            </Button>
            <Button
              onClick={() => setInsightDurationType('weekly')}
              variant="secondary"
              active={insightDurationType === 'weekly'}
            >
              Weekly
            </Button>
          </ButtonGroup>
        </div>
        <div>
          {(emerInsights.length === 0 )
            ? (<Spinner style={{marginTop: "3rem"}} animation="border" variant="light" />)
            : (<SubTitle transitionFactor={ 1 }>
              {emerInsights.map((insightData: EmerInsightInterface) => {

                if (insightDurationType === 'all' ) {
                  return (
                    <span key={insightData.id}>
                      <p
                        className={`emerging-topic-insight-strings insight-string-text ${(insightData.id === selectedInsightID) ? 'insight-string-selected' : ''}`}
                        onClick={() => handleInsightClick(insightData)}
                      >
                        {insightData.insight}
                      </p>
                    </span>
                  )
                } else if (insightDurationType === insightData.anomaly_type) {
                  return (
                    <span key={insightData.id}>
                      <p
                        className={`emerging-topic-insight-strings insight-string-text ${(insightData.id === selectedInsightID) ? 'insight-string-selected' : ''}`}
                        onClick={() => handleInsightClick(insightData)}
                      >
                        {insightData.insight}
                      </p>
                    </span>
                  )
                }

                return (
                  <span key={insightData.id}></span>
                )
              })}
            </SubTitle>
          )}
        </div>
        <SubscriptionModal
          modalShow={modalShow}
          setModalShow={setModalShow}
          isSubscribed={isSubscribed}
          subscriptionInfo={subscriptionInfo}
        />
      </Scrollbars>
    </div>
  )
}


// Exports:
export default EmergingTopicInsights
