// Imports:
import { useSelector } from 'react-redux'

import axios from 'axios'

import { FiThumbsUp } from 'react-icons/fi'
import { FiThumbsDown } from 'react-icons/fi'

import { LIKE_DISLIKE_URL } from '../../Endpoints'

import { AxiosHeadersInterface, WithIndex } from '../../constants/interfaces'

import { getCurrenOrgID } from '../../redux/slices/orgInfoSlice'
import { getLoggedInUser } from '../../redux/slices/authSlice'


// Functions:
const InsightsReaction = ({
  itemId
}:{
  itemId: number
}) => {

  const currOrgID = useSelector(getCurrenOrgID);
  const logInStatus = useSelector(getLoggedInUser);

  const handleClickEvent = async (event_type: string) => {

    try {
      const reqHeaders: WithIndex<AxiosHeadersInterface> = {}
      reqHeaders['X-API-Key'] = process.env.REACT_APP_API_KEY
      await axios({
        method: "post",
        url: LIKE_DISLIKE_URL,
        data: {
          eventType: event_type,
          insight_id: String(itemId),
          org_id: currOrgID,
          user_id: logInStatus.username
        },
        headers: reqHeaders,
      });
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <span className="compare-insight-reaction" >
      <FiThumbsUp style={{ cursor: 'pointer' }} onClick={() => handleClickEvent('like')} ></FiThumbsUp>
      <span> | </span>
      <FiThumbsDown style={{ cursor: 'pointer' }} onClick={() => handleClickEvent('dislike')}></FiThumbsDown>
    </span>
  )
}


// Exports:
export default InsightsReaction
