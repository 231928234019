// Packages:
import { useState } from "react";

import { useSelector } from "react-redux";

import axios from "axios";

import { getCurrenOrgID } from "../../redux/slices/orgInfoSlice";

import { Card, Col, Row, Table } from "react-bootstrap";

import TopBar from "../../components/TopBar";
import EmergingTopicInsights from "./EmergingTopicInsights";
import { EmerTopicWordCloud } from "./EmerTopicWordCloud";

import {
  EMER_TOPICS_GRAPHS,
  EMER_TOPICS_HIST_DATA,
  EMER_TOPICS_WC_DATA,
} from "../../Endpoints";
import {
  AxiosHeadersInterface,
  EmerInsightInterface,
  WithIndex,
  WordCloudDataItemInterface,
} from "../../constants/interfaces";

import { ThreeDots } from "react-loader-spinner";

import { Line } from "react-chartjs-2";
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

// Functions:
const EmergingTopic = () => {
  const currOrgID = useSelector(getCurrenOrgID);

  const [isGraphDataAvailable, setIsGraphDataAvailable] =
    useState<boolean>(false);
  const [insightGraphData, setInsightGraphData] = useState<any>([]);
  const [insightGraphOptions, setInsightGraphOptions] = useState<any>([]);

  const [isInsightClicked, setIsInsightClicked] = useState<boolean>(false);

  const [isHistTableDataAvailable, setIsHistTableDataAvailable] =
    useState<boolean>(false);
  const [histTableData, setHistTableData] = useState<any>([]);

  const [wordCloudData, setWordCloudData] = useState<
    WordCloudDataItemInterface[]
  >([]);
  const [isWordCloudDataAvailable, setIsWordCloudDataAvailable] =
    useState<boolean>(false);

  const [selectedInsightID, setSelectedInsightID] = useState(-1);

  const fetchEmerCharData = async (insightData: EmerInsightInterface) => {
    setIsGraphDataAvailable(false);
    try {
      const reqHeaders: WithIndex<AxiosHeadersInterface> = {};
      reqHeaders["X-API-Key"] = process.env.REACT_APP_API_KEY;
      const res = await axios.get(EMER_TOPICS_GRAPHS, {
        params: {
          date: insightData.end_date,
          org_id: currOrgID,
          anomaly_type: insightData.anomaly_type,
          topic: insightData.topic,
        },
        headers: reqHeaders,
      });

      const data = await res.data;

      setupEmerChartData(data);

      // Display graph
      setIsGraphDataAvailable(true);
    } catch (error) {
      console.error(error);
    }
  };

  const setupEmerChartData = (graph_data: any) => {
    const graph_obj = {
      labels: graph_data.x,
      datasets: [
        {
          label: graph_data.label,
          data: graph_data.y,
          backgroundColor: "#73b7d9",
          fill: true,
        },
      ],
    };
    setInsightGraphData(graph_obj);

    const graph_opts = {
      // maintainAspectRatio: false,  // use to set custom height/width of graph
      plugins: {
        title: {
          display: false,
          // text: graph_data.info_str
        },
        legend: {
          display: false,

          position: "bottom" as const,
        },
      },
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    };
    // Set graph options
    setInsightGraphOptions(graph_opts);
  };

  const fetchHistoryTableData = async (insightData: EmerInsightInterface) => {
    setIsHistTableDataAvailable(false);
    try {
      const reqdate = new Date();
      const currDate =
        reqdate.getFullYear() +
        "-" +
        (reqdate.getMonth() + 1) +
        "-" +
        reqdate.getDate();

      const reqHeaders: WithIndex<AxiosHeadersInterface> = {};
      reqHeaders["X-API-Key"] = process.env.REACT_APP_API_KEY;
      const res = await axios.get(EMER_TOPICS_HIST_DATA, {
        params: {
          date: currDate,
          org_id: currOrgID,
          topic: insightData.topic,
          anomaly_type: insightData.anomaly_type,
          end_date: insightData.end_date,
        },
        headers: reqHeaders,
      });

      const data = await res.data;

      setHistTableData(data);
      setIsHistTableDataAvailable(true);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchWordCloudData = async (insightData: EmerInsightInterface) => {
    setIsWordCloudDataAvailable(false);
    try {
      const reqHeaders: WithIndex<AxiosHeadersInterface> = {};
      reqHeaders["X-API-Key"] = process.env.REACT_APP_API_KEY;
      const res = await axios.get(EMER_TOPICS_WC_DATA, {
        params: {
          org_id: currOrgID,
          start_date: insightData.start_date,
          end_date: insightData.end_date,
        },
        headers: reqHeaders,
      });

      const data = await res.data;

      setWordCloudData(data);
      setIsWordCloudDataAvailable(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleInsightClick = (insightData: EmerInsightInterface) => {
    // show loading spinner
    setIsInsightClicked(true);

    // Load graph
    fetchEmerCharData(insightData);
    // Load history table
    fetchHistoryTableData(insightData);
    // Load Word Cloud data
    fetchWordCloudData(insightData);

    // set selected item id
    setSelectedInsightID(insightData.id);
  };

  return (
    <>
      <TopBar />
      {console.log("WORDcloud data here is", wordCloudData)}
      <div className="main-content-container">
        <span className="main-content-panel">
          {!isHistTableDataAvailable ||
          !isGraphDataAvailable ||
          !isWordCloudDataAvailable ? (
            <>
              {isInsightClicked && (
                <div style={{ marginLeft: "45%", marginTop: "25%" }}>
                  <ThreeDots
                    height="40"
                    width="40"
                    radius="5"
                    color="#fab46e"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    visible={true}
                  />
                </div>
              )}
            </>
          ) : (
            <span>
              <Row>
                <Col>
                  <Card
                    style={{
                      marginTop: "1rem",
                      height: "95%",
                      alignItems: "center",
                    }}
                  >
                    <Line
                      data={insightGraphData}
                      options={insightGraphOptions}
                      style={{ margin: "10% 0.25rem 0.25rem 0.25rem" }}
                    />
                  </Card>
                </Col>
                <Col>
                  <EmerTopicWordCloud
                    wordCloudData={wordCloudData}
                    setWordCloudData={setWordCloudData}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Table striped bordered hover style={{ margin: "2rem 0" }}>
                    <thead>
                      <tr>
                        <th>Insight type</th>
                        <th>Value</th>
                        <th>Change</th>
                        <th>Spotted at</th>
                      </tr>
                    </thead>
                    <tbody>
                      {histTableData.map((rowData: any) => {
                        const spotted_at = new Date(rowData.end_date);

                        return (
                          <tr
                            key={rowData.anomaly_type + rowData.end_date}
                            className={
                              rowData.active ? "selected-table-border" : ""
                            }
                          >
                            <td>{rowData.anomaly_type}</td>
                            <td>{rowData.present_average}</td>
                            <td>{rowData.percentage}%</td>
                            <td>{spotted_at.toLocaleString()}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </span>
          )}
        </span>
        <EmergingTopicInsights
          handleInsightClick={handleInsightClick}
          selectedInsightID={selectedInsightID}
        />
      </div>
    </>
  );
};

// Exports:
export default EmergingTopic;
