import { Card } from 'react-bootstrap';

// For graphs
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";


interface heatMapDataInterface {
  name: string;
  data: number[];
}

interface CatgHeatmapDataInterface {
  id: string;
  data: heatMapDataInterface[];
  columns: string[];
  fields: string[];
  graph_type: string;
}


const CatgHeatmap = ({
  chartData,
} : {
  chartData: CatgHeatmapDataInterface
}) => {

  const chartSeries = chartData.data;

  const chartOpts: ApexOptions = {
    chart: {
      toolbar: {
        show: false,
      }
    },
    dataLabels: {
      enabled: false,
      style: {
        colors: ['#FFFFFF']
      }
    },
    xaxis: {
      type: 'category',
      categories: chartData.columns,
    },
    tooltip: {
      enabled: true,
    },
    colors: ["#000000"],
    title: {
      text: (chartData.fields[2].replaceAll('_', ' ')).toUpperCase(),
      align: 'center',
      offsetY: 10,
      style: {
        fontSize:  '14px',
        fontWeight:  10,
      },
    }
  };

  return (
    <Card className='analyze-graph-card' >
      <div>
        <Chart
          type="heatmap"
          series={chartSeries}
          options={chartOpts}
        />
      </div>
    </Card>
  )
}

// Exports:
export default CatgHeatmap
