import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import styled from "styled-components";
import axios from "axios";
import Color from "color";
import { Spinner, Table } from "react-bootstrap";

import { fadeIn } from "../../../../../styles/animations";
import { getCurrenOrgID } from "../../../../../redux/slices/orgInfoSlice";
import {
  getCurrentEndDate,
  getCurrentStartDate,
} from "../../../../../redux/slices/timeRangeSlice";
import { GET_FEEDBACK_DETAILS } from "../../../../../Endpoints";
import { AxiosHeadersInterface, WithIndex } from "../../../../../constants/interfaces";

const Wrapper = styled.div`
  position: relative;
  min-width: 60rem;
  min-height: 45rem;
  max-width: 95%;
  padding: 1rem;
  color: ${Color("#FFFFFF").darken(0.6).hex()};
  background-color: ${Color("#FFFFFF").darken(0.08).hex()};
  border: 1px solid ${Color("#FFFFFF").darken(0.24).hex()};
  border-radius: 7.5px;
  animation: ${fadeIn(2)} 0.5s ease;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface Props {
  feedback: string | undefined;
}

const FeedbackTable = (props: Props) => {
  const [tableData, setTableData] = useState<any>([]);
  const [headers, setHeaders] = useState<string[]>([]);
  const [isDataAvailable, setIsDataAvailable] = useState(false);
  const [apiError, setApiError] = useState(false);

  const currOrgID = useSelector(getCurrenOrgID);
  const startDate = useSelector(getCurrentStartDate);
  const endDate = useSelector(getCurrentEndDate);

  const fetchData = async () => {
    try {
      const reqHeaders: WithIndex<AxiosHeadersInterface> = {};
      reqHeaders["X-API-Key"] = process.env.REACT_APP_API_KEY;
      const res = await axios.get(GET_FEEDBACK_DETAILS, {
        params: {
          q: "user_rating_detailed",
          org_id: currOrgID,
          start_date: startDate,
          end_date: endDate,
          name: props.feedback,
        },
        headers: reqHeaders,
      });

      const data = await res.data;
      setTableData(data);
      setHeaders(Object.keys(data[0]));
      setIsDataAvailable(true);
    } catch (err) {
      console.log(err);
      setApiError(true);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Wrapper>
      {apiError ? (
        <span id="dashboard-graph-placeholder-text">
          Something went wrong, refresh the page and try again!
        </span>
      ) : (
        <>
          {!isDataAvailable ? (
            <div className="util-center">
              <Spinner animation="border" variant="secondary" />
            </div>
          ) : (
            <Table striped>
              <thead>
                <tr>
                  {headers.map((header, index) => {
                    return <th key={index}>{header}</th>;
                  })}
                </tr>
              </thead>

              <tbody>
                {tableData.map((ticketData: any, index: any) => {
                  return (
                    <tr key={index}>
                      <td>{ticketData.Solved_at}</td>
                      <td>{ticketData.Created_at}</td>
                      <td>{ticketData.Rating}</td>
                      <td>{ticketData.Agent}</td>
                      <td>{ticketData["Ticket type"]}</td>
                      <td>{ticketData["Ticket id "]}</td>
                      <td>{ticketData.Comment ? ticketData.Comment : "-"}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}
        </>
      )}
    </Wrapper>
  );
};

export default FeedbackTable;
