// Packages:
import { useEffect } from 'react'

import Amplify, {Auth} from 'aws-amplify';
import { useSelector, useDispatch } from 'react-redux';

// Components:
import Sidebar from './components/Sidebar'
import AllRoutes from './components/AllRoutes';

import { AuthProvider } from './utils/Auth'

import { loginSuccess } from './redux/slices/authSlice'
import { getUserPoolInfo } from './redux/slices/orgInfoSlice'


// Functions:
const App = () => {

  const dispatch = useDispatch();
  const userPoolInfoFromState = useSelector(getUserPoolInfo);

  useEffect(() => {
    const authenticate = async () => {
      try {
        // Configure amplify with the cached userpool data if available.
        Amplify.configure({
          Auth: userPoolInfoFromState
        })
        // Get user if logged in
        const userDetails = await Auth.currentAuthenticatedUser();
        // Set user details if user is already logged in.
        dispatch(loginSuccess(userDetails))
      } catch (err) {
        console.log(err);
      }
    };
    authenticate();
  }, []);


  return (
    <AuthProvider>
        <div id="app-wrapper">
          <Sidebar />
          <AllRoutes />
        </div>
    </AuthProvider>
  )
}


// Exports:
export default App
