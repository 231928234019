// Packages:
import { useLocation, useNavigate  } from 'react-router-dom';

import {ROUTE} from '../../routes'

// React icons
import { MdDashboard, MdCompareArrows } from 'react-icons/md';
import { BiLogOut, BiBrain } from 'react-icons/bi'
import { BsFillBarChartFill } from 'react-icons/bs';
import { AiOutlineLineChart } from 'react-icons/ai';

import { useSelector } from 'react-redux';
import { useAuth } from '../../utils/Auth';
import { getLoggedInUser } from '../../redux/slices/authSlice';

// Functions:
const Sidebar = () => {

  const navigate = useNavigate();
  const location = useLocation();

  const logInStatus = useSelector(getLoggedInUser);

  const auth = useAuth()

  const onSignOutSubmit = (event: any) => {
    event.preventDefault();
    auth.logout();
  };

  return (
    <>
      {(Object.keys(logInStatus).length > 0 && location.pathname !== '/') &&
        <div id="main-sidebar" >
            <span>
              <div>
                <BiBrain
                  onClick={() => navigate(ROUTE.INDEX)}
                  id='sidebar-app-logo-btn'
                />
              </div>
              <div>
                <MdDashboard
                  onClick={() => navigate(ROUTE.DASHBOARD)}
                  className='main-sidebar-btn'
                  id='sidebar-dashboard-btn'
                  title="Dashboard"
                />
              </div>
              <div>
                <MdCompareArrows
                  onClick={() => navigate(ROUTE.COMPARE)}
                  className='main-sidebar-btn'
                  id='sidebar-compare-btn'
                  title="Compare"
                />
              </div>
              <div>
                <BsFillBarChartFill
                  onClick={() => navigate(ROUTE.ANALYZE)}
                  className='main-sidebar-btn'
                  id='sidebar-analyze-btn'
                  title="Analyze"
                />
              </div>
              <div>
                <AiOutlineLineChart
                  onClick={() => navigate(ROUTE.EMERGING_TOPIC)}
                  className='main-sidebar-btn'
                  id='sidebar-emerging-topic-btn'
                  title="Emerging Topics"
                />
              </div>
              <div>
                <BiLogOut
                  onClick={onSignOutSubmit}
                  className='main-sidebar-btn'
                  id='sidebar-logout-btn'
                  title="Logout"
                />
              </div>
            </span>
        </div>
      }
    </>
  )
}


// Exports:
export default Sidebar
