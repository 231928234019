// Packages:
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import axios from "axios";

import { Scrollbars } from "react-custom-scrollbars";

import { BASE_INSIGHT_URL } from "../../../Endpoints";

import styled from "styled-components";
import { fadeIn } from "../../../styles/animations";

import { Spinner } from "react-bootstrap";

import { getCurrenOrgID } from "../../../redux/slices/orgInfoSlice";
import {
  getCurrentStartDate,
  getCurrentEndDate,
} from "../../../redux/slices/timeRangeSlice";

import {
  AxiosHeadersInterface,
  WithIndex,
} from "../../../constants/interfaces";

const Title = styled.div<{ transitionFactor: number }>`
  margin-top: 0.9rem;
  font-weight: 700;
  font-size: 1.3rem;
  animation: ${(props) => fadeIn(props.transitionFactor + 3)}
    ${(props) => 0.25 * props.transitionFactor}s ease;
  user-select: none;
`;

const SubTitle = styled.div<{ transitionFactor: number }>`
  margin-top: 0.25rem;
  display: inline;
  text-align: left;
  font-weight: 400;
  font-size: 0.9rem;
  animation: ${(props) => fadeIn(props.transitionFactor + 3)}
    ${(props) => 0.25 * props.transitionFactor}s ease;
`;

interface InsightListInterface {
  id: number;
  insight: string;
  url: string;
  sub_list: string[];
}

const hasSubList = ["created_ticket_volume"];

// Functions:
const Insights = ({
  setGraphUrl,
}: {
  setGraphUrl: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const startDate = useSelector(getCurrentStartDate);
  const endDate = useSelector(getCurrentEndDate);

  const currOrgID = useSelector(getCurrenOrgID);

  const [currentSubListOpen, setCurrentSubListOpen] = useState<string>("");

  const [apiResponseError, setApiResponseError] = useState(false);

  const [selectedInsightID, setSelectedInsightID] = useState(-1);

  const [insightList, setInsightList] = useState<InsightListInterface[]>([]);
  const getInsightList = useCallback(async () => {
    try {
      // Start spinner while we fetch data.
      setInsightList([]);

      const reqHeaders: WithIndex<AxiosHeadersInterface> = {};
      reqHeaders["X-API-Key"] = process.env.REACT_APP_API_KEY;
      const res = await axios.get(BASE_INSIGHT_URL, {
        params: {
          q: "master_fx",
          org_id: currOrgID,
          start_date: startDate,
          end_date: endDate,
        },
        headers: reqHeaders,
      });
      const data = await res.data;
      setInsightList(data);
    } catch (error) {
      console.error(error);
      setApiResponseError(true);
    }
  }, [currOrgID, startDate, endDate]);

  const handleInsightClick = (insightData: InsightListInterface) => {
    setGraphUrl(insightData.url);
    setCurrentSubListOpen("");

    setSelectedInsightID(insightData.id);

    if (hasSubList.includes(insightData.url)) {
      setCurrentSubListOpen(insightData.url);
    }
  };

  useEffect(() => {
    getInsightList();
    setApiResponseError(false);
  }, [getInsightList]);

  // Return:
  return (
    <div id="insight-bar">
      <Scrollbars autoHide>
        <Title
          style={{ marginTop: 0, marginBottom: "0.5rem" }}
          transitionFactor={1}
        >
          Insights
        </Title>
        {apiResponseError ? (
          "Set a different time range"
        ) : (
          <span>
            {insightList.length === 0 ? (
              <Spinner
                style={{ marginTop: "3rem" }}
                animation="border"
                variant="light"
              />
            ) : (
              <span>
                <SubTitle transitionFactor={1}>
                  {insightList.map((insightData: InsightListInterface) => {
                    // Add a insight sub-list if available.
                    if (hasSubList.includes(insightData.url)) {
                      return (
                        <span key={insightData.id}>
                          <p
                            className="insight-string-text"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleInsightClick(insightData)}
                          >
                            {insightData.insight}.
                          </p>
                          {currentSubListOpen === insightData.url &&
                            insightData.sub_list.map(
                              (subInsight: string, index) => {
                                return (
                                  <p
                                    className="insight-string-text"
                                    key={index}
                                    style={{ marginLeft: "1.5rem" }}
                                  >
                                    ~ {subInsight}.
                                  </p>
                                );
                              }
                            )}
                        </span>
                      );
                    }

                    return (
                      <span key={insightData.id}>
                        <p
                          className={`insight-string-text ${
                            insightData.id === selectedInsightID
                              ? "insight-string-selected"
                              : ""
                          }`}
                          style={{ cursor: "pointer" }}
                          onClick={() => handleInsightClick(insightData)}
                        >
                          {insightData.insight}.
                        </p>
                      </span>
                    );
                  })}
                </SubTitle>
              </span>
            )}
          </span>
        )}
      </Scrollbars>
    </div>
  );
};

// Exports:
export default Insights;
