import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  LoggedInUser: {},
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSuccess: (state: any, action: any) => {
      state.LoggedInUser = action.payload;
    },
    logoutSuccess: (state: any) => {
      state.LoggedInUser = {};
    }
  }
});

export const getLoggedInUser = (state: any) => state.auth.LoggedInUser;

export const { loginSuccess, logoutSuccess } = authSlice.actions;

export default authSlice.reducer;
