// Packages:
import styled from 'styled-components'
import Color from 'color'

import { useDispatch, useSelector } from 'react-redux';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { 
  getCurrentStartDate,
  getCurrentEndDate,
  setStartDate,
  setEndDate
} from '../../redux/slices/timeRangeSlice';

// Components:
import SearchBar from '../SearchBar'

// Styles:
import { fadeIn } from '../../styles/animations'
import { Button } from 'react-bootstrap';
import { useState } from 'react';

const Wrapper = styled.div`
  margin-left: 4rem;
  top: 0;
  left: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100vw - 4rem - 3rem);
  height: calc(4rem - 1rem);
  padding: 0.5rem 1.5rem;
  background-color: ${ Color('#FFFFFF').darken(0.05).hex() };
  border-bottom: 1px solid ${ Color('#FFFFFF').darken(0.15).hex() };
  animation : ${ fadeIn('-3rem', 'Y', true, true) } 0.5s ease;
`


// Functions:
const TopBar = () => {

  const dispatch = useDispatch();
  const startDate = new Date(useSelector(getCurrentStartDate));
  const endDate = new Date(useSelector(getCurrentEndDate));

  const [localStartDate, setLocalStartDate] = useState(startDate);
  const [localEndDate, setLocalEndDate] = useState(endDate);

  const handleStartDateSelection = (date: Date | null) => {
    if (date !== null) {
      setLocalStartDate(date)
    }
  }

  const handleEndDateSelection = (date: Date | null) => {
    if (date !== null) {
      setLocalEndDate(date)
    }
  }

  const handleNewTimeRangeSubmit = () => {
    dispatch(setStartDate(localStartDate));
    dispatch(setEndDate(localEndDate));
  }

  return (
    <Wrapper>
      <SearchBar />
      <div style= {{ marginRight: "2rem", float: "right", display: "flex", alignItems: "center" }}>
        <span>
          <DatePicker
            selected={localStartDate}
            onChange={(date) => handleStartDateSelection(date)}
            selectsStart
            startDate={localStartDate}
            endDate={localEndDate}
          />
        </span>
        <span style={{ marginLeft: "1rem" }} >
          <DatePicker
            selected={localEndDate}
            onChange={(date) => handleEndDateSelection(date)}
            selectsEnd
            startDate={localStartDate}
            endDate={localEndDate}
            minDate={localStartDate}
          />
        </span>
        <span>
          <Button
            style={{marginLeft: "1rem"}}
            variant="primary"
            onClick={handleNewTimeRangeSubmit}
          >
            Go
          </Button>
        </span>
      </div>
    </Wrapper>
  )
}


// Exports:
export default TopBar
