// Exports:
export enum ROUTE {
  INDEX = "/",
  LOGIN = "/login",
  DASHBOARD = "/dashboard",
  FEEDBACK = "/feedback",
  COMPARE = "/compare",
  ANALYZE = "/analyze",
  EMERGING_TOPIC = "/emergingtopic",
}
