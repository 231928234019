import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  orgID: '',
  orgName: 'No organization selected',
  orgUserPoolInfo: {
    region: '',
    userPoolId: '',
    userPoolWebClientId: '',
  },
};

export const orgInfoSlice = createSlice({
  name: 'orgInfo',
  initialState,
  reducers: {
    setCurrentOrgInfo: (state: any, action: any) => {
      state.orgID = action.payload.orgID;
      state.orgName = action.payload.orgName;
      state.orgUserPoolInfo = action.payload.orgUserPoolInfo;
    }
  }
});

export const getCurrenOrgID = (state: any) => state.orgInfo.orgID;
export const getCurrentOrgName = (state: any) => state.orgInfo.orgName;
export const getUserPoolInfo = (state: any) =>  state.orgInfo.orgUserPoolInfo;

export const { setCurrentOrgInfo } = orgInfoSlice.actions;

export default orgInfoSlice.reducer;
