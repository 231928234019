// Packages:
import { useState } from "react";

import { Badge, Card } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";

import ReactWordcloud, { OptionsProp } from "react-wordcloud";
import { WordCloudDataItemInterface } from "../../../constants/interfaces";

// Functions:
const EmerTopicWordCloud = ({
  wordCloudData,
  setWordCloudData,
}: {
  wordCloudData: WordCloudDataItemInterface[];
  setWordCloudData: React.Dispatch<React.SetStateAction<any>>;
}) => {
  const [doReload, setDoReload] = useState<boolean>(false);

  const [removedWCData, setRemovedWCData] = useState<
    WordCloudDataItemInterface[]
  >([]);

  const wc_options: OptionsProp = {
    enableTooltip: true,
    deterministic: false,
    fontFamily: "impact",
    fontSizes: [20, 60],
    fontStyle: "normal",
    fontWeight: "normal",
    padding: 1,
    rotations: 1,
    rotationAngles: [0, 90],
    scale: "sqrt",
    spiral: "archimedean",
    transitionDuration: 1000,
  };

  const removeWordFromWC = (word: string) => {
    setDoReload(false);

    let ind: number = -1;

    for (let i = 0; i < wordCloudData.length; i++) {
      if (wordCloudData[i].text === word) {
        ind = i;

        const tempArray = removedWCData;
        tempArray.push(wordCloudData[i]);
        setRemovedWCData(tempArray);

        break;
      }
    }

    if (ind > -1) {
      const temp_array = wordCloudData;
      temp_array.splice(ind, 1);
      setWordCloudData(temp_array);
      setDoReload(true);
    }
  };

  const addWordtoWC = (item: WordCloudDataItemInterface) => {
    setDoReload(false);

    let ind: number = -1;

    for (let i = 0; i < removedWCData.length; i++) {
      if (removedWCData[i].text === item.text) {
        ind = i;

        const tempArray = wordCloudData;
        tempArray.push(item);
        setWordCloudData(tempArray);

        break;
      }
    }

    if (ind > -1) {
      const temp_array = removedWCData;
      temp_array.splice(ind, 1);
      setRemovedWCData(temp_array);
      setDoReload(true);
    }
  };

  const wordCloudCallbacks = {
    onWordClick: (word: WordCloudDataItemInterface) =>
      removeWordFromWC(word.text),
    getWordTooltip: () => "Click to remove.",
  };

  // Return:
  return (
    <Card style={{ margin: "1rem 0", height: "95%", alignItems: "center" }}>
      <div style={{ marginTop: "1rem" }}>
        <div style={{ marginBottom: "0.5rem" }}>
          {removedWCData.map((data: WordCloudDataItemInterface) => {
            return (
              <Badge
                key={data.text}
                pill
                bg="secondary"
                style={{ margin: "0 0.25rem" }}
              >
                {data.text}{" "}
                <AiOutlineClose
                  onClick={() => addWordtoWC(data)}
                  style={{ cursor: "pointer" }}
                />
              </Badge>
            );
          })}
        </div>
        <div>
          <ReactWordcloud
            words={wordCloudData}
            options={wc_options}
            callbacks={wordCloudCallbacks}
          />
        </div>
      </div>
    </Card>
  );
};

// Exports:
export { EmerTopicWordCloud };
