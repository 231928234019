import { useState, useEffect } from 'react'

import Spinner from 'react-bootstrap/Spinner'
import Button from 'react-bootstrap/Button'

import { Modal, Card } from 'react-bootstrap';

import axios from 'axios';

import { useSelector } from 'react-redux';

import { BiExpandAlt } from 'react-icons/bi'
import { AiOutlineClose } from 'react-icons/ai'

import { BASE_INSIGHT_URL } from '../../../../Endpoints';

import { getCurrenOrgID } from '../../../../redux/slices/orgInfoSlice';
import { getCurrentEndDate, getCurrentStartDate } from '../../../../redux/slices/timeRangeSlice';

import { AxiosHeadersInterface, WithIndex } from '../../../../constants/interfaces';
// For graphs
// import Chart from 'chart.js/auto';   // Should include all but doesn't work?
import {Line} from 'react-chartjs-2';
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip
} from 'chart.js';

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip
);


const LineGraph = ({
  graphUrl,
  handleGraphCardClose
} : {
  graphUrl: string
  handleGraphCardClose: (url: string) => void
}) => {

  const currOrgID = useSelector(getCurrenOrgID);
  const startDate = useSelector(getCurrentStartDate);
  const endDate = useSelector(getCurrentEndDate);

  const [ticketData, setTicketData] = useState<any>([])
  const [graphOptions, setGraphOptions] = useState<any>([]);
  const [isDataAvailable, setIsDataAvailable] = useState(false);

  const [modalShow, setModalShow] = useState<boolean>(false);

  const fetchData = async () => {
    try {
      const reqHeaders: WithIndex<AxiosHeadersInterface> = {}
      reqHeaders['X-API-Key'] = process.env.REACT_APP_API_KEY
      const res = await axios.get(BASE_INSIGHT_URL, {
        params: {
          q: graphUrl,
          org_id: currOrgID,
          start_date: startDate,
          end_date: endDate,
        },
        headers: reqHeaders,
      });
      const data = await res.data;

      const res2 = await axios.get(BASE_INSIGHT_URL, {
        params: {
          q: 'high_impact_so',
          org_id: currOrgID,
          start_date: startDate,
          end_date: endDate,
        },
        headers: reqHeaders,
      });
      const data2 = await res2.data;

      getChartData(data, data2);
    } catch (error) {
      console.error(error);
    }
  };

  const getChartData = (res_data: any, res_data2: any) => {
    let chart_obj = {
        labels: res_data.x,
        datasets: [
          {
            id: '1',
            label: res_data.label,
            data: res_data.y,
            backgroundColor: "#73b7d9",
          }, {
            id: '2',
            label: res_data2.label,
            data: res_data2.y,
            backgroundColor: "#f2ec41",
          }
        ]
      }
    setTicketData(chart_obj);

    const char_opts = {
      plugins: {
        title: {
          display: true,
          text: res_data.str
        },
        legend: {
          display: true,
          position: "bottom"
        },
      }
    };
    setGraphOptions(char_opts);

    setIsDataAvailable(true);
  };

  useEffect(() => {
    setIsDataAvailable(false);
    fetchData();
  }, []);

  return (
    <Card >
      <Card.Header className="graph-card-header draggable-item" >
        <AiOutlineClose className="graph-close-btn compare-graph-card-btn" onClick={() => handleGraphCardClose(graphUrl)} />
        {(Object.keys(ticketData).length > 0) &&
          <BiExpandAlt 
            className="expand-item-btn compare-graph-card-btn" 
            onClick={() => setModalShow(true)}
            title="Expand Graph"
          />
        }
      </Card.Header>
      <div className="grahpviz-wrapper" >
        {(!isDataAvailable) ? (<Spinner style={{marginTop: "5rem"}} animation="border" variant="secondary" />)
          : (<Line
              data={ticketData}
              options={graphOptions}
              style={{padding: "0.5rem"}}
            />
          )
        }
        <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered
          show={modalShow}
          onHide={() => setModalShow(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Zoomed in View
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Line
              data={ticketData}
              options={graphOptions}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setModalShow(false)}>Close</Button>
          </Modal.Footer>
        </Modal>
      </div>
    </Card>
  )
}

// Exports:
export default LineGraph
