// Packages:
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import Color from 'color'
import { useNavigate } from 'react-router-dom'
import { useDebounce } from 'react-use'


// Imports:
import { BiBrain } from 'react-icons/bi'


// Constants:
import COLOR from '../../constants/colors'
import { ROUTE } from '../../routes'


// Styles:
import { fadeIn } from '../../styles/animations'

const Wrapper = styled.div<{ shouldUnmount: boolean }>`
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100vw - 4rem);
  height: calc(100vh - 4rem);
  padding: 2rem;
  background: ${Color(COLOR.SECONDARY).hex()};
  filter: ${ props => props.shouldUnmount ? 'opacity(0)' : 'opacity(1)' };
  transition: all 0.5s ease;
`

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #FFFFFF;
  font-weight: 600;
  user-select: none;
  cursor: pointer;
  animation : ${ fadeIn(4) } 0.75s ease;
`

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  height: 5rem;
  padding: 0.25rem;
  color: ${ Color(COLOR.PRIMARY).lighten(0.7).hex() };
  background: ${ Color(COLOR.PRIMARY).lighten(0).hex() };
  border-radius: 15px;
`


// Functions:
const Index = () => {
  // Constants:
  const navigate = useNavigate()

  // State:
  const [ shouldUnmount, setShouldUnmount ] = useState(false)

  // Effects:
  useDebounce(() => shouldUnmount ? navigate(ROUTE.DASHBOARD) : null, 550, [ shouldUnmount ])

  useEffect(() => {
    // Automatically re-direct user to dashboard/login after 10 seconds.
    setTimeout(() => setShouldUnmount(true), 10000)
  }, []);

  // Return:
  return (
    <Wrapper shouldUnmount={ shouldUnmount }>
      <Button onClick={ () => setShouldUnmount(true) }>
        <Icon><BiBrain fontSize='4rem' /></Icon>
        <span style={{ marginTop: '0.5rem' }}>Insights</span>
      </Button>
    </Wrapper>
  )
}


// Exports:
export default Index
