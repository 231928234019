// Packages:
import styled from "styled-components";

// URLs
import { BASE_INSIGHT_URL, LIKE_DISLIKE_URL } from "../../../Endpoints";

// React Bootstrap components
import { Form, Spinner } from "react-bootstrap";

import { Scrollbars } from "react-custom-scrollbars";

// Styles:
import { fadeIn } from "../../../styles/animations";

import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import InsightsReaction from "../../../components/InsightsReaction";
import { RiSortAsc, RiSortDesc } from "react-icons/ri";
import { useSelector } from "react-redux";
import { getCurrenOrgID } from "../../../redux/slices/orgInfoSlice";
import {
  getCurrentEndDate,
  getCurrentStartDate,
} from "../../../redux/slices/timeRangeSlice";
import {
  AxiosHeadersInterface,
  WithIndex,
} from "../../../constants/interfaces";

const Title = styled.div<{ transitionFactor: number }>`
  margin-top: 0.9rem;
  font-weight: 700;
  font-size: 1.3rem;
  animation: ${(props) => fadeIn(props.transitionFactor + 3)}
    ${(props) => 0.25 * props.transitionFactor}s ease;
  user-select: none;
`;

const SubTitle = styled.div<{ transitionFactor: number }>`
  margin-top: 0.25rem;
  margin-right: 0.75rem;
  font-weight: 400;
  font-size: 0.9rem;
  animation: ${(props) => fadeIn(props.transitionFactor + 3)}
    ${(props) => 0.25 * props.transitionFactor}s ease;
`;

interface InsightListInterface {
  id: number;
  insight: string;
  url: string;
  graph_type: string;
}

// Functions:
const CompareInsights = ({
  setGraphUrls,
  graphUrls,
  setDoReload,
  style,
  handleChange,
}: {
  setGraphUrls: React.Dispatch<React.SetStateAction<string[]>>;
  graphUrls: string[];
  setDoReload: React.Dispatch<React.SetStateAction<boolean>>;
  style?: React.CSSProperties;
  handleChange: (e: any) => void;
}) => {
  const currOrgID = useSelector(getCurrenOrgID);
  const startDate = useSelector(getCurrentStartDate);
  const endDate = useSelector(getCurrentEndDate);

  const [insightList, setInsightList] = useState<InsightListInterface[]>([]);

  const [apiResponseError, setApiResponseError] = useState(false);

  const getInsightList = useCallback(async () => {
    try {
      setInsightList([]);
      const reqHeaders: WithIndex<AxiosHeadersInterface> = {};
      reqHeaders["X-API-Key"] = process.env.REACT_APP_API_KEY;
      const res = await axios.get(BASE_INSIGHT_URL, {
        params: {
          q: "master_fx",
          org_id: currOrgID,
          start_date: startDate,
          end_date: endDate,
        },
        headers: reqHeaders,
      });
      setInsightList(res.data);
      setShowSortedList(false);
    } catch (error) {
      console.error(error);
      setApiResponseError(true);
    }
  }, [currOrgID, startDate, endDate]);

  useEffect(() => {
    getInsightList();
    setApiResponseError(false);
  }, [getInsightList]);

  const [showSortedList, setShowSortedList] = useState<boolean>(false);
  const getVotedInsightList = async () => {
    try {
      const reqHeaders: WithIndex<AxiosHeadersInterface> = {};
      reqHeaders["X-API-Key"] = process.env.REACT_APP_API_KEY;
      const res = await axios.get(LIKE_DISLIKE_URL, {
        params: {
          org_id: currOrgID,
          start_date: startDate,
          end_date: endDate,
        },
        headers: reqHeaders,
      });

      return res.data.data;
    } catch (error) {
      console.error(error);
    }

    return;
  };

  const sortInsightList = async (doSort: boolean) => {
    setShowSortedList(doSort);

    if (doSort) {
      const votingRankList = await getVotedInsightList();
      const ogInsightList = insightList;

      let newInsightList: any[] = [];

      for (const ins_id of votingRankList) {
        for (let i = 0; i < ogInsightList.length; i++) {
          if (String(ogInsightList[i].id) === ins_id) {
            newInsightList.push(ogInsightList[i]);
            break;
          }
        }
      }

      for (let i = 0; i < ogInsightList.length; i++) {
        if (!votingRankList.includes(String(ogInsightList[i].id))) {
          newInsightList.push(ogInsightList[i]);
        }
      }
      setInsightList(newInsightList);
    } else {
      await getInsightList();
    }
  };

  // Return:
  return (
    <div id="compare-insight-bar">
      <Scrollbars autoHide>
        <Title style={{ marginTop: 0 }} transitionFactor={1}>
          Insights
        </Title>
        {!apiResponseError && (
          <div style={{ marginRight: "1rem" }}>
            {showSortedList ? (
              <RiSortAsc
                title="Default sort"
                style={{ float: "right", cursor: "pointer" }}
                onClick={() => sortInsightList(false)}
              />
            ) : (
              <RiSortDesc
                title="Sort by likes"
                style={{ float: "right", cursor: "pointer" }}
                onClick={() => sortInsightList(true)}
              />
            )}
          </div>
        )}
        {apiResponseError ? (
          "Set a different time range"
        ) : (
          <span>
            {insightList.length === 0 ? (
              <Spinner
                style={{ marginTop: "3rem", marginLeft: "2rem" }}
                animation="border"
                variant="light"
              />
            ) : (
              <SubTitle transitionFactor={1}>
                <Form style={{ marginTop: "2rem" }}>
                  {insightList.map((insightData: InsightListInterface) => {
                    return (
                      <span
                        key={insightData.id}
                        className="compare-insight-item"
                      >
                        <InsightsReaction itemId={insightData.id} />
                        <Form.Check
                          className="compare-insight-string insight-string-text"
                          aria-label={`insight-${insightData.id}`}
                          label={insightData.insight}
                          value={insightData.url}
                          id={insightData.url}
                          onChange={(e: any) => handleChange(e)}
                          checked={graphUrls.includes(insightData.url)}
                        />
                      </span>
                    );
                  })}
                </Form>
              </SubTitle>
            )}
          </span>
        )}
      </Scrollbars>
    </div>
  );
};

// Exports:
export default CompareInsights;
