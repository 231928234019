// Imports
import { createContext, useContext } from "react";
import { useDispatch } from "react-redux";

import { Auth } from 'aws-amplify';
import { toast } from "react-toastify";

import { useNavigate } from "react-router-dom";
import {ROUTE} from '../routes'

import { loginSuccess, logoutSuccess } from "../redux/slices/authSlice";


const AuthContext = createContext<{
  login: (username: any, password: any) => void;
  logout: () => any;
  validatePassword: (username: string, code: string, new_password: string) => void;
  sendCode: (username: string) => void;
  changePassword: (oldpassword: string, newpassword: string) => void;
  getCurrentUser: () => any;
  // signUp: (username: any, password: any) => void;
  // confirmSignUp: (username: any, code: any) => void;
}>({
  login: () => {},
  logout: () => {},
  validatePassword: () => {},
  sendCode: () => {},
  changePassword: () => {},
  getCurrentUser: () => {},
  // signUp: () => {},
  // confirmSignUp: () => {}
});

export const AuthProvider = ({children}: {children: any}) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getCurrentUser = async () => {

    let isUserAuthenticated: boolean = false;

    await Auth.currentAuthenticatedUser()
      .then((user) => {
        isUserAuthenticated = true
      })
      .catch((err) => {
        console.error(err);
      });

    return isUserAuthenticated

  };

  const login = async (username: any, password: any) => {

    Auth.signIn(username, password)
      .then((user) => {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {

          let newPassword = prompt('New Password Required');
          // need to switch undef, or ts complains
          if (!newPassword) {
            newPassword = "passVal1@";
          }
          Auth.completeNewPassword(
            user, // the Cognito User Object
            newPassword
          )
            .then((user) => {
                console.log("success")
            })
            .catch((e) => {
            console.log("failed login")
            console.error(e)
            });
        } else {
            // ON LOGIN SUCCESS
            // Update the state
            dispatch(loginSuccess(user))
            navigate(ROUTE.DASHBOARD)
        }
      })
      .catch((e) => {
        alert("Incorrect username or password");
        console.error(e)
      });
  };

  // const signUp = async (username: any, password: any) => {
  //   try {
  //     const { user } : any = await Auth.signUp({
  //       username,
  //       password
  //     })
  //     console.log(user);

  //   } catch (e) {
  //     console.error(e);
  //   }
  // }

  // const confirmSignUp = async (username: string, code: string) => {
  //   try {
  //     const res = await Auth.confirmSignUp(username, code);
  //     console.log(res)
  //   } catch (error) {
  //       console.log('error confirming sign up', error);
  //   }
  // }


  const sendCode = (username: string) => {
    Auth.forgotPassword(username)
      .then((data) => {
        //console.log(data)
        toast.success('Code sent successfully');
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const changePassword = (oldpassword: string, newpassword: string) => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(user, oldpassword, newpassword);
      })
      .then((data) => {
        toast.success('Password changed successfuly');
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const validatePassword = (username: string, code: string, new_password: string) => {
    Auth.forgotPasswordSubmit(username, code, new_password)
      .then((data) => {
        console.log('Password changed successfully');
        // navigate(ROUTE.LOGIN);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const logout = () => {
    signOut();
  };
  async function signOut() {
    try {
      await Auth.signOut();
      // Update state
      dispatch(logoutSuccess());
      navigate(ROUTE.LOGIN)
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        validatePassword,
        sendCode,
        changePassword,
        getCurrentUser,
        // signUp,
        // confirmSignUp
      }}
    >
      {children}
    </AuthContext.Provider>
  );

}

export const useAuth = () => {
  return useContext(AuthContext);
};
