import { Card } from 'react-bootstrap';

import { Scatter } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend);


interface ScatterPlotDataInterface {
  id: string;
  data: any[];
  fields: string[];
  graph_type: string;
  corr: number;
}


const NumScatterPlot = ({
  chartData,
} : {
  chartData: ScatterPlotDataInterface
}) => {

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  const r_val = String(chartData.corr)
  const label_str = (chartData.fields[0].replaceAll('_', ' ')).toUpperCase() + " vs " + (chartData.fields[1].replaceAll('_', ' ')).toUpperCase() + " (" + r_val + ")";

  const data = {
    datasets: [
      {
        label: label_str,
        data: chartData.data,
        backgroundColor: 'rgba(255, 99, 132, 1)',
      },
    ],
  };

  return (
    <Card className='analyze-graph-card' >
      <div className='analyze-graph-card-content' >
        <Scatter
          data={data}
          options={options}
          style={{padding: "1rem"}}
        />
      </div>
    </Card>
  )
}

// Exports:
export default NumScatterPlot
