// Packages:
import styled from 'styled-components'

import { fadeIn } from '../../../styles/animations'


const SubTitle = styled.div<{ transitionFactor: number }>`
  margin-top: 0.25rem;
  font-weight: 400;
  font-size: 0.9rem;
  animation : ${ props => fadeIn(props.transitionFactor + 3) } ${ props => 0.25 * props.transitionFactor }s ease;
`

interface AnalyzeInsightItemInterface {
  id: string;
  insight_str: string;
}

interface AnalyzeInsightInterface {
  num_fields_heatmap: AnalyzeInsightItemInterface[];
  catg_stat_heatmap: AnalyzeInsightItemInterface[];
}

// Functions:
const AnalyzeInsights = ({
  analysisInsights,
} : {
  analysisInsights: AnalyzeInsightInterface
}) => {


  // Return:
  return (
    <div>
      <SubTitle transitionFactor={ 1 }>
        {(analysisInsights.num_fields_heatmap.length === 0 &&
          analysisInsights.catg_stat_heatmap.length === 0) ? <span>Initiate an analysis to populate insights.</span>
          : (<span>
            {analysisInsights.num_fields_heatmap.map((insight: AnalyzeInsightItemInterface) => {
              return (
                <div
                  key={insight.id}
                  className='analyze-insight-string insight-string-text'
                >{insight.insight_str}</div>
              )
            })}
            {analysisInsights.catg_stat_heatmap.map((insight: AnalyzeInsightItemInterface) => {
              return (
                <div
                  key={insight.id}
                  className='analyze-insight-string insight-string-text'
                >{insight.insight_str}</div>
              )
            })}
          </span>)
        }
      </SubTitle>
    </div>

  )
}


// Exports:
export default AnalyzeInsights
