// Packages:
import React from "react";
import { useParams } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";

// Components:
import TopBar from "../../../../components/TopBar";
import FeedbackTable from "./FeedbackTable";
import FeedbackWordCloud from "./Wordcloud";

const Feedback = () => {
  const params = useParams();
  const { feedbackType } = params;

  return (
    <div>
      <TopBar />
      <div className="main-content-container">
        <span className="main-content-panel">
          <Scrollbars>
            <FeedbackTable feedback={feedbackType} />
          </Scrollbars>
        </span>
        <div className="wordcloud-panel">
          <FeedbackWordCloud feedback={feedbackType} />
        </div>
      </div>
    </div>
  );
};

export default Feedback;
