import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import axios from "axios";
import Table from "react-bootstrap/Table";

import { GET_DRILLDOWN_VIEW_DETAILS } from "../../../../Endpoints";
import { getCurrenOrgID } from "../../../../redux/slices/orgInfoSlice";
import {
  getCurrentEndDate,
  getCurrentStartDate,
} from "../../../../redux/slices/timeRangeSlice";
import { ThreeDots } from "react-loader-spinner";
import OverlayModal from "../DrillDownOverlayCard/OverlayModal";
import { AxiosHeadersInterface, WithIndex } from "../../../../constants/interfaces";

const BootstrapTable = ({ graphUrl }: { graphUrl: string }) => {
  const currOrgID = useSelector(getCurrenOrgID);
  const startDate = useSelector(getCurrentStartDate);
  const endDate = useSelector(getCurrentEndDate);

  const [data, setData] = useState<any[]>([]);
  const [headers, setHeaders] = useState<string[]>([]);
  const [isDataAvailable, setIsDataAvailable] = useState(false);

  const [apiResponseError, setApiResponseError] = useState(false);

  const [isOverlayVisible, setIsOverlayVisible] = useState<boolean>(false);
  const changeOverlayCardState = () => {
    setIsOverlayVisible(false);
  };

  const fetchData = async () => {
    try {
      const reqHeaders: WithIndex<AxiosHeadersInterface> = {};
      reqHeaders["X-API-Key"] = process.env.REACT_APP_API_KEY;
      const res = await axios.get(GET_DRILLDOWN_VIEW_DETAILS, {
        params: {
          q: graphUrl,
          org_id: currOrgID,
          start_date: startDate,
          end_date: endDate,
        },
        headers: reqHeaders,
      });
      const data = await res.data;
      setData(data);
      setHeaders(Object.keys(data[0]));
      setIsDataAvailable(true);
    } catch (error) {
      console.error(error);
      setApiResponseError(true);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  let agentName = useRef("");
  const getDrillDownDetails = (assignee: string) => {
    agentName.current = assignee;
    setIsOverlayVisible(true);
  };

  return (
    <>
      {apiResponseError ? (
        <span id="dashboard-graph-placeholder-text">
          Something went wrong, refresh the page and try again!
        </span>
      ) : (
        <div>
          {!isDataAvailable ? (
            <div id="dashboard-graph-spinner">
              <ThreeDots
                height="40"
                width="40"
                radius="5"
                color="#fab46e"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                visible={true}
              />
            </div>
          ) : (
            <>
              {isOverlayVisible && (
                <OverlayModal
                  display="table"
                  graphUrl={"agent_performance_drilldown"}
                  agentName={agentName.current}
                  isVisible={isOverlayVisible}
                  changeOverlayCardState={changeOverlayCardState}
                />
              )}

              <h5>{graphUrl}</h5>
              <Table striped>
                <thead>
                  <tr>
                    {headers.map((header, index) => {
                      return <th key={index}>{header}</th>;
                    })}
                  </tr>
                </thead>

                <tbody>
                  {data.map((ticketData, index) => {
                    return (
                      <tr key={index}>
                        <td
                          style={{
                            cursor: "pointer",
                            backgroundColor: "#dfdfdf",
                          }}
                          onClick={() => {
                            getDrillDownDetails(ticketData.Assignee);
                          }}
                        >
                          {ticketData.Assignee}
                        </td>
                        <td>{ticketData.IRT}</td>
                        <td>{ticketData.ART}</td>
                        <td>{ticketData.RR}</td>
                        <td>{ticketData.CSAT}</td>
                        <td>{ticketData["Solved tickets"]}</td>
                        <td>{ticketData.score}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default BootstrapTable;
