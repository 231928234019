// Packages:
import { useEffect, useState } from 'react'

// Constants:
import { TOPIC_MODEL_URL, FEEDBACK_TRENDS_URL } from '../../Endpoints'

// React-bootstrap
import { Row, Col, Spinner, Button, Table, Card, Badge } from 'react-bootstrap'

// React components
import CompareBarGraph from './GraphViz/CompareBarGraph'
import CompareInsights from './CompareInsights'
import DoughnutGraph from './GraphViz/DoughnutGraph'
import LineGraph from './GraphViz/LineGraph'
import TopBar from '../../components/TopBar'

// React-icons
import { FiRefreshCcw } from 'react-icons/fi'
import { AiOutlineClose } from 'react-icons/ai'

// Misc
import Draggable from 'react-draggable';
import axios from 'axios'

import ReactWordcloud, { OptionsProp } from 'react-wordcloud';

import { useSelector } from 'react-redux'
import { getCurrenOrgID } from '../../redux/slices/orgInfoSlice'
import { getCurrentEndDate, getCurrentStartDate } from '../../redux/slices/timeRangeSlice'

import { AxiosHeadersInterface, WithIndex } from '../../constants/interfaces'

// For graphs
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);


const doughnut_graph_params = ['user_ratings', 'csat_rating']
const line_graph_params = ['high_impact_cr', 'high_impact_so']

interface WordCloudInterface {
  "text": string
  "value": number
}

// Functions:
const Compare = () => {

  const currOrgID = useSelector(getCurrenOrgID);
  const startDate = useSelector(getCurrentStartDate);
  const endDate = useSelector(getCurrentEndDate);

  const [graphUrls, setGraphUrls] = useState<string[]>([]);
  const [doReload, setDoReload] = useState<boolean>(false);

  const [showLowerComponent, setShowLowerComponent] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(true);
  const [searchQueryTM, setSearchQueryTM] = useState<string>('null');

  const [isTopicDataAvailable, setIsTopicDataAvailable] = useState<boolean>(false);

  const [topicData, setTopicData] = useState<any>();
  const [wordCloudContent, setWordCloudContent] = useState<WordCloudInterface[]>([]);

  const [currentRecordID, setCurrentRecordID] = useState<string>('');

  const [TMActiveRow, setTMActiveRow] = useState<number>(-1);

  // Topic trends
  const [trendReqStatus, setTrendReqStatus] = useState<number>(0);
  const [recordIDList, setRecordIDList] = useState<string[]>([]);
  const [topicDataForTrends, setTopicDataForTrends] = useState<any[]>([]);

  const [topicTrendsData, setTopicTrendsData] = useState<any[]>([]);
  const [isTrendsDataAvailable, setIsTrendsDataAvailable] = useState<boolean>(false);

  const [trendWCData, setTrendWCData] = useState<WordCloudInterface[]>([]);
  const [trendsActiveRow, setTrendsActiveRow] = useState<number>(-1);

  const [currTrends, setCurrTrends] = useState<any[]>([]);
  const [showCurrTrends, setShowCurrTrends] = useState<boolean>(false);

  const [trendChartData, setTrendChartData] = useState<any>([]);
  const [trendChartOptions, setTrendChartOptions] = useState<any>([]);

  const [showTrendGraph, setShowTrendGraph] = useState<boolean>(false);

  const [showTrendWordCloud, setShowTrendWordCloud] = useState<boolean>(false);

  const [trendsLabelList, setTrendsLabelList] = useState<string[]>([]);

  const wc_options: OptionsProp = {
    enableTooltip: true,
    deterministic: false,
    fontFamily: "impact",
    fontSizes: [20, 60],
    fontStyle: "normal",
    fontWeight: "normal",
    padding: 1,
    rotations: 1,
    rotationAngles: [0, 90],
    scale: "sqrt",
    spiral: "archimedean",
    transitionDuration: 1000
  };

  // Function to handle closing of compare graphs.
  const handleGraphCardClose = (url: string) => {
    setGraphUrls(graphUrls.filter(val => val !== url))
  }

  // Function to handle clicks on the check boxes in Compare Insights component.
  const handleChange = (e: any)  =>  {
    e.persist();

    const isChecked = e.target.checked;
    const url = e.target.value;

    if (isChecked) {
      setGraphUrls([...graphUrls, url]);
    } else {
      const ind = graphUrls.indexOf(url);
      const temp_array = graphUrls;
      if (ind > -1) {
        temp_array.splice(ind, 1);     // remove that item
        setGraphUrls(temp_array);
      }
      // close the graph card if the insight is unchecked.
      handleGraphCardClose(url);
    }
  }

  const fetchFeedbackData : any = async (q_rating: string) => {
    try {
      const reqHeaders: WithIndex<AxiosHeadersInterface> = {}
      reqHeaders['X-API-Key'] = process.env.REACT_APP_API_KEY
      const res = await axios({
        method: 'get',
        url: FEEDBACK_TRENDS_URL,
        params: {
          org_id: currOrgID,
          start_date: startDate,
          end_date: endDate,
          reqType: "TOPIC_MODEL_SOURCE_DATA",
          rating: q_rating,
        },
        headers: reqHeaders,
      });

      return res.data;
    } catch (error) {
      console.error(error);
    }

    return;
  }

  const fetchTMData = async () => {
    try {
      const reqHeaders: WithIndex<AxiosHeadersInterface> = {}
      reqHeaders['X-API-Key'] = process.env.REACT_APP_API_KEY
      const res = await axios({
        method: 'get',
        url: TOPIC_MODEL_URL,
        params: {
          record_id: currentRecordID,
        },
        headers: reqHeaders,
      });
      const data = await res.data;
      setTopicData(data);

      return data;
    } catch (error) {
      console.error(error);
    }

    return;
  }

  const makeTopicModellingRequest = async (q_rating: string) => {
    setIsTopicDataAvailable(false)

    try {
      // fetch required ticket data 
      const feedbackData = await fetchFeedbackData(q_rating)

      const reqHeaders: WithIndex<AxiosHeadersInterface> = {}
      reqHeaders['X-API-Key'] = process.env.REACT_APP_API_KEY
      const res = await axios({
        method: 'post',
        url: TOPIC_MODEL_URL,
        data: {
          orgID: currOrgID,
          data: feedbackData,
          rating: 'null',
        },
        headers: reqHeaders,
      });
      const data = await res.data;

      setCurrentRecordID(data.record_id)

    } catch (error) {
      console.error(error);
    }

  }

  const handleGTMBtnClick = async () => {
    setShowLowerComponent(true)
    setIsProcessing(true)
    setSearchQueryTM('null')
    setWordCloudContent([])

    // Re-initialize trends related states.
    setCurrTrends([]);
    setShowCurrTrends(false);
    setShowTrendGraph(false);
    setShowTrendWordCloud(false);

    setTrendChartOptions([]);
    setTrendChartData([]);
    setTrendsActiveRow(-1);
    setTrendWCData([]);
    setTrendReqStatus(0);

    setTopicDataForTrends([]);
    setTopicTrendsData([]);

    setIsTrendsDataAvailable(false);

    // Send api request of topic modelling without query param
    await makeTopicModellingRequest('null');
  }

  const handleGraphItemClick = async (q: string) => {
    setShowLowerComponent(true)
    setIsProcessing(true)
    setSearchQueryTM(q)
    setWordCloudContent([])

    // Re-initialize trends related states.
    setCurrTrends([]);
    setShowCurrTrends(false);
    setShowTrendGraph(false);
    setShowTrendWordCloud(false);

    setTrendChartOptions([]);
    setTrendChartData([]);
    setTrendsActiveRow(-1);
    setTrendWCData([]);
    setTrendReqStatus(0);

    setTopicDataForTrends([]);
    setTopicTrendsData([]);

    setIsTrendsDataAvailable(false);

    // Send api request of topic modelling with query param "q"
    await makeTopicModellingRequest(q);
  }

  const refreshRequestStatus = async () => {
    setShowLowerComponent(true)
    setIsProcessing(true)

    // Make another api request and check if Topic model data has been generated.
    const topicData_res = await fetchTMData();

    // Hide the loading spinner and show data if completed.
    if (topicData_res.process_status === "completed") {
      setIsProcessing(false)
      setIsTopicDataAvailable(true)
    }
  }

  const hideLowerComponent = () => {
    setShowLowerComponent(false)
  }

  const handleTopicNumClick = (word_cloud : any, index: number, topic_num: number) => {
    setWordCloudContent(word_cloud)

    setTMActiveRow(index)

    // Only execute when we have already retrieved trend data
    if (isTrendsDataAvailable) {

      setCurrTrends(topicTrendsData[topic_num])
      setShowCurrTrends(true)

      setShowTrendWordCloud(false);

      // Since a new overall topic was select, unselect trends table row.
      setTrendsActiveRow(-1);

      // Get trend size array for the graph.
      let trendSizeList: number[] = [];

      for (let i = 0; i < topicTrendsData[topic_num].length; i++ ) {
        trendSizeList[i] = topicTrendsData[topic_num][i].size
      }

      const chart_obj = {
        labels: trendsLabelList,
        datasets: [
          {
            data: trendSizeList,
            backgroundColor: "#73b7d9",
            type: "line",
          },
        ]
      };
      setTrendChartData(chart_obj);

      const char_opts = {
        plugins: {
          title: {
            display: true,
            text: "Weekly trends"
          },
          legend: {
            display: false,
            position: "bottom"
          },
        }
      };
      setTrendChartOptions(char_opts);
      setShowTrendGraph(true);
    }
  }

  useEffect(() => {
    setDoReload(false)
  }, [doReload, graphUrls, startDate])


  // Funcs for topic trends

  const getSplitFeedbackData = async () => {
    try {
      const reqHeaders: WithIndex<AxiosHeadersInterface> = {}
      reqHeaders['X-API-Key'] = process.env.REACT_APP_API_KEY
      const res = await axios({
        method: 'get',
        url: FEEDBACK_TRENDS_URL,
        params: {
          org_id: currOrgID,
          start_date: startDate,
          end_date: endDate,
          reqType: "TOPIC_TRENDS_TM_SOURCE_DATA",
          rating: searchQueryTM,
        },
        headers: reqHeaders,
      });
      const data = await res.data;

      // Set the chart labels for topic trends.
      setTrendsLabelList(data.labels)

      return data.tm_data;

    } catch (error) {
      console.error(error);
    }

    return;
  }

  const makeTMRequestForTrends = async () => {

    setRecordIDList([])

    const split_feedback_data = await getSplitFeedbackData();

    let temp_record_list: string[] = [];

    const len = Object.keys(split_feedback_data).length;

    for (let i = 1; i <= len; i++) {

      try {
        const reqHeaders: WithIndex<AxiosHeadersInterface> = {}
        reqHeaders['X-API-Key'] = process.env.REACT_APP_API_KEY
        const res = await axios({
          method: 'post',
          url: TOPIC_MODEL_URL,
          data: {
            orgID: currOrgID,
            data: split_feedback_data[i],
          },
          headers: reqHeaders,
        })
        const data = await res.data;
        temp_record_list.push(data.record_id);

      } catch (error) {
        console.error(error);
      }

    }
    setRecordIDList(temp_record_list)
    setTrendReqStatus(1)

  }

  const checkTrendsTMStatus = async () => {

    let isTrendTMComplete = true;
    let temp_tm_data_list: string[] = [];

    for (let i = 0; i < recordIDList.length; i++) {

      try {
        const reqHeaders: WithIndex<AxiosHeadersInterface> = {}
        reqHeaders['X-API-Key'] = process.env.REACT_APP_API_KEY
        const res = await axios({
          method: 'get',
          url: TOPIC_MODEL_URL,
          params: {
            record_id: recordIDList[i],
          },
          headers: reqHeaders,
        });
        const data = await res.data;

        if (data.process_status === 'completed') {
          temp_tm_data_list.push(data);
        } else {
          isTrendTMComplete = false;
        }
      } catch (error) {
        console.error(error);
      }
    }

    // This will be true iff all process status were marked as completed
    if (isTrendTMComplete) {
      setTopicDataForTrends(temp_tm_data_list);
      setTrendReqStatus(2);
    }
  }

  const fetchTopicTrends = async () => {

    try {
      const reqHeaders: WithIndex<AxiosHeadersInterface> = {}
      reqHeaders['X-API-Key'] = process.env.REACT_APP_API_KEY
      const res = await axios({
        method: 'POST',
        url: FEEDBACK_TRENDS_URL,
        data: {
          overall_topic_data: topicData,
          split_topic_data: topicDataForTrends,
        },
        headers: reqHeaders,
      });
      // use the data
      const data = await res.data;
      setTopicTrendsData(data)
      setIsTrendsDataAvailable(true)
      
    } catch (error) {
      console.error(error);
    }
  }

  const handleTrendItemClick = (trendItem: any, index: number) => {
    // Set Word cloud data
    setTrendWCData(trendItem.wc)
    // Set active row
    setTrendsActiveRow(index)

    setShowTrendWordCloud(true);
    
  }

  return (
    <div>
      <TopBar />
      <div className="main-content-container">
        <span className='main-content-panel' >
          <Row xs={1} md={3} className="g-3">
            {graphUrls.map((url: string) => {

              let graphComponent : JSX.Element;

              if (doughnut_graph_params.includes(url)) {
                graphComponent = <DoughnutGraph graphUrl={url} handleGraphItemClick={handleGraphItemClick} handleGraphCardClose={handleGraphCardClose} handleGTMBtnClick={handleGTMBtnClick} />;
              } else if (line_graph_params.includes(url)) {
                graphComponent = <LineGraph graphUrl={url} handleGraphCardClose={handleGraphCardClose} />;
              } else {
                graphComponent = <CompareBarGraph graphUrl={url} handleGraphCardClose={handleGraphCardClose} />
              }

              return (
                <Draggable bounds="parent" handle=".graph-card-header" key={url} >
                  <Col className='compare-content-column' >
                    {graphComponent}
                  </Col>
                </Draggable>
              )
            })}
          </Row>
          <Row style={{ marginTop: "1.5rem", marginBottom: "1rem", padding: "0.5rem" }} hidden={!showLowerComponent} >
            <Col>
              <Card>
                <Card.Header>
                  <AiOutlineClose onClick={hideLowerComponent} style={{ float: "right", cursor: "pointer" }} />
                </Card.Header>
                <div style={{ padding: "0.5rem", minHeight: "20rem" }} >
                  {isProcessing &&
                    <span>
                      <Spinner animation="grow" size="sm" style={{ marginRight: "0.5rem" }} /> Topic Model is being generated...
                      <Button onClick={refreshRequestStatus} style={{ marginLeft: "0.5rem" }}><FiRefreshCcw /></Button>
                    </span>
                  }
                  { (!isProcessing) &&
                    <span>
                      {(searchQueryTM !== '' && searchQueryTM !== 'null') &&
                        <h5>
                          <Badge pill bg="secondary">
                            {searchQueryTM} <AiOutlineClose onClick={() => setSearchQueryTM('')} style={{cursor: "pointer" }} />
                          </Badge>{' '}
                        </h5>
                      }
                      {(isTopicDataAvailable) && 
                        <span>
                          {(wordCloudContent.length !== 0) &&
                            <div>
                              <ReactWordcloud 
                                words={wordCloudContent}
                                options={wc_options}
                              />
                            </div>
                          }
                          <Table style={{ margin: "1rem 0 1rem 0" }} striped bordered hover >
                            <thead>
                              <tr>
                                <th>Topic</th>
                                <th>Size</th>
                                <th>Top topics</th>
                              </tr>
                            </thead>
                            <tbody>
                              {(topicData.data).map((item: any, index: number) => {
                                return (
                                  <tr 
                                    key={index} 
                                    onClick={() => handleTopicNumClick(item.word_cloud, index, item.topic_number)}
                                    style={{ cursor: "pointer" }}
                                    className={(TMActiveRow === index) ? 'selected-table-border' : ''}
                                  >
                                    <td>{item.topic_number}</td>
                                    <td>{item.ticket_ids.length }</td>
                                    <td>
                                      {(item.word_cloud).map((wc_item: WordCloudInterface, index: number) => {
                                        if (index < 4) {
                                          return (
                                            <span key={index} >{ wc_item.text }, </span>
                                          )
                                        } else if (index === 4) {
                                          return (
                                            <span key={index} >{ wc_item.text }</span>
                                          )
                                        }
                                        // Return nothing for indices that are higher than needed.
                                        return <></> ;
                                      })}
                                    </td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </Table>
                          <div>
                            {(trendReqStatus === 0) && <Button onClick={makeTMRequestForTrends} > Initiate Topic Trends </Button> }
                            {(trendReqStatus === 1) && 
                              <span>
                                <Spinner animation="grow" size="sm" style={{ marginRight: "0.5rem" }} />
                                Gathering data...
                                <Button onClick={checkTrendsTMStatus} style={{ marginLeft: "0.5rem" }}  ><FiRefreshCcw /></Button></span> }
                            {(trendReqStatus === 2 && !isTrendsDataAvailable) && <Button onClick={fetchTopicTrends} > Get Topic Trends </Button> }
                          </div>
                          {showCurrTrends && 
                            <span>
                              {(showTrendGraph) && <Row style={{ margin: "1rem 0" }} >
                                <Col  style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                  <span style={{ width: "50%" }}>
                                    <Line
                                      data={trendChartData}
                                      options={trendChartOptions}
                                      
                                    />
                                  </span>
                                </Col>
                              </Row>}
                              {(showTrendWordCloud) && <Row  style={{ margin: "1rem 0 " }} >
                                <Col style={{ width: "40%", float: "left"}} >
                                  <ReactWordcloud 
                                    words={wordCloudContent}
                                    options={wc_options}
                                  />
                                </Col>
                                <Col style={{ width: "40%", float: "right"}} >
                                  <ReactWordcloud 
                                    words={trendWCData}
                                    options={wc_options}
                                  />
                                </Col> 
                              </Row>}
                              <Row  style={{ margin: "1rem 0" }} >
                                <Col>
                                  <Table style={{ margin: "1rem 0 1rem 0" }} striped bordered hover >
                                    <thead>
                                      <tr>
                                        <th>Week</th>
                                        <th>Size</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {currTrends.map((trendItem: any, index: number) => {
                                        return (
                                          <tr 
                                            key={index} 
                                            onClick={() => handleTrendItemClick(trendItem, index)}
                                            style={{ cursor: "pointer" }}
                                            className={(trendsActiveRow === index) ? 'selected-table-border' : ''}
                                          >
                                            <td>{trendItem.segment}</td>
                                            <td>{trendItem.size}</td>
                                          </tr>
                                        )
                                      })}
                                    </tbody>
                                  </Table>
                                </Col>
                              </Row>
                            </span>
                          }
                        </span>
                      }
                    </span>
                  }
                </div>
              </Card>
            </Col>
          </Row>
        </span>
        <CompareInsights
          setGraphUrls={setGraphUrls}
          graphUrls={graphUrls}
          setDoReload={setDoReload}
          handleChange={handleChange}
        />
      </div>
    </div>
  )
}


// Exports:
export default Compare
