import { useSelector } from "react-redux";
import { Routes, Route, Navigate } from "react-router-dom";

import { getLoggedInUser } from "../../redux/slices/authSlice";
import { ROUTE } from "../../routes";

// Component Imports
import Analyze from "../../views/Analyze";
import Compare from "../../views/Compare";
import Dashboard from "../../views/Dashboard";
import Feedback from "../../views/Dashboard/DataViz/Feedback";
import EmergingTopic from "../../views/EmergingTopic";
import Index from "../../views/Index";
import Login from "../../views/Login";

const AllRoutes = () => {
  const logInStatus = useSelector(getLoggedInUser);

  return (
    <>
      {Object.keys(logInStatus).length > 0 ? (
        <Routes>
          <Route path={ROUTE.INDEX} element={<Index />} />
          <Route path={ROUTE.DASHBOARD} element={<Dashboard />} />
          <Route
            path={ROUTE.FEEDBACK + "/:feedbackType"}
            element={<Feedback />}
          />
          <Route path={ROUTE.ANALYZE} element={<Analyze />} />
          <Route path={ROUTE.COMPARE} element={<Compare />} />
          <Route path={ROUTE.EMERGING_TOPIC} element={<EmergingTopic />} />
          <Route path="*" element={<Navigate to={ROUTE.DASHBOARD} replace />} />
        </Routes>
      ) : (
        <Routes>
          <Route path={ROUTE.INDEX} element={<Index />} />
          <Route path={ROUTE.LOGIN} element={<Login />} />
          <Route path="*" element={<Navigate to={ROUTE.LOGIN} replace />} />
        </Routes>
      )}
    </>
  );
};

// Exports:
export default AllRoutes;
