// Packages:
import styled from 'styled-components'
import Color from 'color'


// Imports:
import { IoSearch } from 'react-icons/io5'


// Styles:
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30rem;
  height: 1rem;
  padding: 0.6rem 0.5rem;
  border-radius: 0.3rem;
  background-color: ${ Color('#FFFFFF').darken(0.12).hex() };
`

const Input = styled.input`
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  outline: none;
  font-family: 'Inter';
  font-weight: 500;
  font-size: 1rem;

  &::placeholder {
    font-family: 'Inter';
    font-weight: 500;
    color: ${ Color('#FFFFFF').darken(0.4).hex() };
  }
`


// Functions:
const SearchBar = () => {
  return (
    <Wrapper>
      <IoSearch
        color={ Color('#FFFFFF').darken(0.4).hex() }
        fontSize='1.2rem'
        style={{
          marginTop: '-0.1rem',
          padding: '0 0.5rem'
        }}
      />
      <Input type='text' placeholder='Search for something..' />
    </Wrapper>
  )
}


// Exports:
export default SearchBar
