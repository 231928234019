// Packages:
import { keyframes } from 'styled-components'


// Exports:
export const fadeIn = (t?: any, d?: 'Y' | 'X', opposite?: boolean, custom?: boolean) => keyframes`
  0% {
    filter: opacity(0);
    transform: ${ `translate${ d ?? 'Y' }(${ custom ? t : ((!!t ? (opposite ? -t : t) : 1) * 5) + 'px' })` };
  }
`

export const growIn = keyframes`
  0% {
    filter: opacity(0);
    transform: scale(0);
  }
`
