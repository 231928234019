import { createSlice } from "@reduxjs/toolkit";
import { addDays, parseISO } from 'date-fns'

const currDate = new Date();
const defaultStartDate = addDays(parseISO(currDate.toISOString()), -7)

const initialState = {
  startDate: defaultStartDate,
  endDate: currDate,
};

export const timeRangeSlice = createSlice({
  name: 'timeRangeInfo',
  initialState,
  reducers: {
    setStartDate: (state: any, action: any) => {
      state.startDate = action.payload;
    },
    setEndDate: (state: any, action: any) => {
      state.endDate = action.payload;
    }
  }
});

export const getCurrentStartDate = (state: any) => state.timeRange.startDate;
export const getCurrentEndDate = (state: any) => state.timeRange.endDate;

export const { setStartDate, setEndDate } = timeRangeSlice.actions;

export default timeRangeSlice.reducer;
