import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import orgInfoReducer from './slices/orgInfoSlice';
import timeRangeReducer from './slices/timeRangeSlice';

// Redux-persist
import { combineReducers } from 'redux';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';

import localforage from 'localforage';

const persistConfig = {
  key: 'root',
  storage: localforage,
};

const reducers = combineReducers({
  auth: authReducer,
  orgInfo: orgInfoReducer,
  timeRange: timeRangeReducer,
});
// Create a persistent reducer
const persistedReducer = persistReducer(persistConfig, reducers);

const store =  configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export default store;
