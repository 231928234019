// Exports:

// For Org 1 & Org 2
export const TYPE1_FIELDS = [
  "REPLIES",
  "IRT",
  "RT",
  "AGENT WAIT TIME",
  "REQUESTER WAIT TIME",
  "ON HOLD TIME",
  "REPLY TIME",
  "CSAT RESPONSE",
  "CSAT RATING",
  "GROUP"
]

// For Org 3
export const TYPE2_FIELDS = [
  "REPLIES",
  "IRT",
  "RT",
  "REQUESTER WAIT TIME",
  "REPLY TIME",
  "CSAT RESPONSE",
  "CSAT RATING",
  "GROUP",
]

export const ANALYSIS_ORG1_MAP = {
  "REPLIES": "replies",
  "IRT": "first_resolution_time_in_minutes",
  "RT": "full_resolution_time_in_minutes",
  "AGENT WAIT TIME": "agent_wait_time_in_minutes",
  "REQUESTER WAIT TIME": "requester_wait_time_in_minutes",
  "ON HOLD TIME": "on_hold_time_in_minutes",
  "REPLY TIME": "reply_time_in_minutes",
  "CSAT RATING": "csat",
  "GROUP": "group",
  "CSAT RESPONSE": "csat_response"
}

export const ANALYSIS_ORG2_MAP = {
  "REPLIES": "replies",
  "IRT": "first_resolution_time_in_minutes",
  "RT": "full_resolution_time_in_minutes",
  "AGENT WAIT TIME": "agent_wait_time_in_minutes",
  "REQUESTER WAIT TIME": "requester_wait_time_in_minutes",
  "ON HOLD TIME": "on_hold_time_in_minutes",
  "REPLY TIME": "reply_time_in_minutes",
  "CSAT RATING": "sentiment",
  "GROUP": "type",
  "CSAT RESPONSE": "csat_response"
}

export const ANALYSIS_ORG3_MAP = {
  "REPLIES": "replies",
  "IRT": "first_resolution_time_in_minutes",
  "RT": "full_resolution_time_in_minutes",
  "REQUESTER WAIT TIME": "requester_wait_time_in_minutes",
  "REPLY TIME": "reply_time_in_minutes",
  "GROUP": "type",
  "CSAT RATING": "csat",
  "CSAT RESPONSE": "csat_response"
}
