import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { getCurrenOrgID } from "../../../../redux/slices/orgInfoSlice";
import {
  getCurrentEndDate,
  getCurrentStartDate,
} from "../../../../redux/slices/timeRangeSlice";
import { GET_DRILLDOWN_VIEW_DETAILS } from "../../../../Endpoints";
import axios from "axios";
import { Table } from "react-bootstrap";
import { AxiosHeadersInterface, WithIndex } from "../../../../constants/interfaces";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export type IgraphData = {
  x: (number | string)[];
  y: (number | string)[];
};

type IDrillDownOverlayCard = {
  display: string;
  graphUrl: string;
  agentName: string;
  isVisible: any;
  changeOverlayCardState?: () => void;
};

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Chart.js Bar Chart",
    },
  },
};

const OverlayModal = ({
  display,
  graphUrl,
  agentName,
  isVisible,
  changeOverlayCardState,
}: IDrillDownOverlayCard) => {
  const currOrgID = useSelector(getCurrenOrgID);
  const startDate = useSelector(getCurrentStartDate);
  const endDate = useSelector(getCurrentEndDate);

  const [ticketData, setTicketData] = useState<any>([]);
  const [graphOptions, setGraphOptions] = useState<any>([]);
  const [isDataAvailable, setIsDataAvailable] = useState(false);

  const [tableData, setTableData] = useState<any>([]);
  const [headers, setHeaders] = useState<string[]>([]);

  const [apiError, setApiError] = useState(false);

  const fetchData = async () => {
    try {
      const reqHeaders: WithIndex<AxiosHeadersInterface> = {};
      reqHeaders["X-API-Key"] = process.env.REACT_APP_API_KEY;
      const res = await axios.get(GET_DRILLDOWN_VIEW_DETAILS, {
        params: {
          q: graphUrl,
          org_id: currOrgID,
          start_date: startDate,
          end_date: endDate,
          name: agentName,
        },
        headers: reqHeaders,
      });
      const data = await res.data;
      if (display === "bar") {
        getChartData(data);
      } else {
        setTableData(data);
        setHeaders(Object.keys(data[0]));
        setIsDataAvailable(true);
      }
    } catch (error) {
      setApiError(true);
      console.error(error);
    }
  };

  const getChartData = (data: any) => {
    const chart_obj = {
      labels: data.x,
      datasets: [
        {
          label: agentName,
          data: data.y,
          backgroundColor: "#a6a4cc",
          type: "bar",
        },
      ],
    };
    setTicketData(chart_obj);

    const chart_options = {
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: true,
          text: `${agentName} : ${graphUrl}`,
        },
      },
    };
    setGraphOptions(chart_options);

    setIsDataAvailable(true);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="modal-50w"
        show={isVisible}
        onHide={changeOverlayCardState}
      >
        <Modal.Header closeButton></Modal.Header>
        {apiError ? (
          <span id="dashboard-graph-placeholder-text">
            Something went wrong, refresh the page and try again!
          </span>
        ) : (
          <>
            {!isDataAvailable ? (
              <div className="util-center">
                <Spinner animation="border" variant="secondary" />
              </div>
            ) : (
              <>
                {display === "bar" ? (
                  <Bar data={ticketData} options={graphOptions} />
                ) : (
                  <Table striped>
                    <thead>
                      <tr>
                        {headers.map((header, index) => {
                          return <th key={index}>{header}</th>;
                        })}
                      </tr>
                    </thead>

                    <tbody>
                      {tableData.map((ticketData: any, index: any) => {
                        return (
                          <tr key={index}>
                            <td>{ticketData.Type}</td>
                            <td>{ticketData.IRT}</td>
                            <td>{ticketData.ART}</td>
                            <td>{ticketData.RR}</td>
                            <td>{ticketData.CSAT}</td>
                            <td style={{ textAlign: "center" }}>
                              {ticketData["Solved tickets"]}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                )}
              </>
            )}
          </>
        )}
      </Modal>
    </>
  );
};

export default OverlayModal;
