// Packages:
import styled from "styled-components";

import { fadeIn } from "../../../styles/animations";

import { Button, Form, Tab, Tabs } from "react-bootstrap";
import { useEffect, useState } from "react";

import { Scrollbars } from "react-custom-scrollbars";

import { useSelector } from "react-redux";
import { getCurrenOrgID } from "../../../redux/slices/orgInfoSlice";

import {
  ANALYSIS_ORG1_MAP,
  ANALYSIS_ORG2_MAP,
  ANALYSIS_ORG3_MAP,
  TYPE1_FIELDS,
  TYPE2_FIELDS,
} from "../../../constants";

import AnalyzeInsights from "../AnalyzeInsights";

const SubTitle = styled.div<{ transitionFactor: number }>`
  margin-top: 0.25rem;
  font-weight: 400;
  font-size: 0.9rem;
  animation: ${(props) => fadeIn(props.transitionFactor + 3)}
    ${(props) => 0.25 * props.transitionFactor}s ease;
`;

interface AnalyzeInsightItemInterface {
  id: string;
  insight_str: string;
}

interface AnalyzeInsightInterface {
  num_fields_heatmap: AnalyzeInsightItemInterface[];
  catg_stat_heatmap: AnalyzeInsightItemInterface[];
}

// Functions:
const AnalyzePanel = ({
  fetchAnalysisData,
  analysisInsights,
}: {
  fetchAnalysisData: (fields: string[]) => void;
  analysisInsights: AnalyzeInsightInterface;
}) => {
  const currOrgID = useSelector(getCurrenOrgID);

  const [availableDataFields, setAvailableDataFields] = useState<string[]>([]);
  const [selectedFields, setSelectedFields] = useState<string[]>([]);

  // const [showAnalyzeBtn, setShowAnalyzeBtn] = useState(true);

  useEffect(() => {
    if (
      currOrgID === "623c0630ecd09b16265bb5df" ||
      currOrgID === "619ba7459b8e9669aaf47fa3"
    ) {
      setAvailableDataFields(TYPE1_FIELDS);
    } else {
      setAvailableDataFields(TYPE2_FIELDS);
    }
  }, []);

  const removeCsatField = (data_field: string) => {
    let fieldToRemove: string;

    if (data_field === "CSAT RATING") {
      fieldToRemove = "CSAT RESPONSE";
    } else if (data_field === "CSAT RESPONSE") {
      fieldToRemove = "CSAT RATING";
    } else {
      return;
    }

    const ind = selectedFields.indexOf(fieldToRemove);
    const temp_array = selectedFields;
    if (ind > -1) {
      temp_array.splice(ind, 1); // remove that item
      setSelectedFields([...temp_array]);
    }
  };

  const handleCheckboxClick = (e: any) => {
    e.persist();

    const isChecked = e.target.checked;
    const data_field = e.target.value;

    if (isChecked) {
      const temp_array = selectedFields;
      temp_array.push(data_field);
      setSelectedFields([...temp_array]);
    } else {
      const ind = selectedFields.indexOf(data_field);
      const temp_array = selectedFields;
      if (ind > -1) {
        temp_array.splice(ind, 1); // remove that item
        setSelectedFields([...temp_array]);
      }
    }

    removeCsatField(data_field);
  };

  const get_db_field_names = () => {
    let fields_map: any;
    if (currOrgID === "623c0630ecd09b16265bb5df") {
      fields_map = ANALYSIS_ORG1_MAP;
    } else if (currOrgID === "619ba7459b8e9669aaf47fa3") {
      fields_map = ANALYSIS_ORG2_MAP;
    } else if (currOrgID === "623889684c3aef24fd6938b0") {
      fields_map = ANALYSIS_ORG3_MAP;
    }

    let db_fields: string[] = [];
    for (const item in selectedFields) {
      db_fields.push(fields_map[selectedFields[item]]);
    }

    return db_fields;
  };

  const initiateAnalysis = () => {
    setSelectedFields([]);

    const db_field_names = get_db_field_names();
    fetchAnalysisData(db_field_names);
  };

  // Return:
  return (
    <div id="analysis-selection-bar" style={{ float: "right" }}>
      <Scrollbars autoHide>
        <div>
          <Tabs
            defaultActiveKey="controls"
            id="analyze-panel-tabs"
            className="mb-3 analyze-tabs"
          >
            <Tab
              eventKey="controls"
              title="Controls"
              className="analyze-tab-item"
            >
              <div>
                <SubTitle transitionFactor={1}>
                  <Form>
                    {availableDataFields.map((dataField: string) => {
                      return (
                        <span
                          key={dataField}
                          className="analyze-control-item"
                          style={{ display: "inline" }}
                        >
                          <Form.Check
                            label={dataField.toUpperCase()}
                            value={dataField}
                            id={dataField}
                            onChange={(e: any) => handleCheckboxClick(e)}
                            checked={selectedFields.includes(dataField)}
                            style={{
                              boxSizing: "border-box",
                              margin: "0.5rem",
                              textAlign: "left",
                              maxWidth: "80%",
                            }}
                          />
                        </span>
                      );
                    })}
                  </Form>
                </SubTitle>
              </div>
              <Button
                variant="secondary"
                style={{ marginLeft: "7rem", marginRight: "5rem" }}
                onClick={initiateAnalysis}
              >
                Analyze
              </Button>
            </Tab>
            <Tab
              eventKey="insights"
              title="Insights"
              className="analyze-tab-item"
            >
              <AnalyzeInsights analysisInsights={analysisInsights} />
            </Tab>
          </Tabs>
        </div>
      </Scrollbars>
    </div>
  );
};

// Exports:
export default AnalyzePanel;
