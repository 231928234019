import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import BootstrapTable from "../DrillDownTableView";
import randomColor from "randomcolor";
import { ThreeDots } from "react-loader-spinner";

import { BASE_INSIGHT_URL } from "../../../../Endpoints";
import { getCurrenOrgID } from "../../../../redux/slices/orgInfoSlice";
import {
  getCurrentStartDate,
  getCurrentEndDate,
} from "../../../../redux/slices/timeRangeSlice";

import {
  AxiosHeadersInterface,
  WithIndex,
} from "../../../../constants/interfaces";

import OverlayModal, { IgraphData } from "../DrillDownOverlayCard/OverlayModal";

// For graphs
import { Bar, getElementAtEvent } from "react-chartjs-2";
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
} from "chart.js";
import { useNavigate } from "react-router-dom";
import { ROUTE } from "../../../../routes";

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip
);

const bestAndWorstInsight: string[] = ["worst_agent", "best_agent"];

const MiscGraph = ({
  graphUrl,
  setGraphStatData,
}: {
  graphUrl: string;
  setGraphStatData: React.Dispatch<React.SetStateAction<any>>;
}) => {
  const navigate = useNavigate();

  const currOrgID = useSelector(getCurrenOrgID);
  const startDate = useSelector(getCurrentStartDate);
  const endDate = useSelector(getCurrentEndDate);

  const [ticketData, setTicketData] = useState<any>([]);
  const [graphOptions, setGraphOptions] = useState<any>([]);
  const [isDataAvailable, setIsDataAvailable] = useState(false);

  const [apiResponseError, setApiResponseError] = useState(false);

  //state for the type of graph
  const [graphType, setGraphType] = useState("");

  //state for the overlay card
  const [isOverlayVisible, setIsOverlayVisible] = useState<boolean>(false);

  const changeOverlayCardState = () => {
    setIsOverlayVisible(false);
  };

  let chartRef = useRef(null);

  const fetchData = async () => {
    try {
      const reqHeaders: WithIndex<AxiosHeadersInterface> = {};
      reqHeaders["X-API-Key"] = process.env.REACT_APP_API_KEY;
      const res = await axios.get(BASE_INSIGHT_URL, {
        params: {
          q: graphUrl,
          org_id: currOrgID,
          start_date: startDate,
          end_date: endDate,
        },
        headers: reqHeaders,
      });
      const data = await res.data;
      getChartData(data);
    } catch (error) {
      console.error(error);
      setApiResponseError(true);
    }
  };

  const getChartData = async (res_data: any) => {
    const num_data_points = res_data.x.length;

    const char_opts = {
      plugins: {
        title: {
          display: true,
          text: res_data.str,
        },
        legend: {
          display: true,
          position: "bottom",
        },
      },
    };

    if (res_data.graph_type === "bar") {
      setGraphType("bar");

      const chart_obj = {
        labels: res_data.x,
        datasets: [
          {
            type: "bar",
            label: res_data.label,
            data: res_data.y,
            backgroundColor: "#4c4899",
          },
          {
            type: "line",
            label: "Upper Control Limit",
            borderColor: "#a9c9fc",
            data: Array(num_data_points).fill(res_data.ucl),
          },
          {
            type: "line",
            label: "Lower Control Limit",
            borderColor: "#ffa79c",
            data: Array(num_data_points).fill(Math.max(res_data.lcl, 0)), // If val is negative, make lcl 0
          },
          {
            type: "line",
            label: "Mean",
            borderColor: "#ffd8a1",
            data: Array(num_data_points).fill(res_data.avg),
          },
        ],
      };

      // If a trendline can be added, then add it.
      if (res_data.has_trend) {
        const trend_obj = {
          type: "line",
          label: "Trendline",
          borderColor: "#e5bfff",
          data: res_data.trend_vals,
        };
        chart_obj.datasets.push(trend_obj);
      }

      //Setting data for the Bar Graph
      setTicketData(chart_obj);
    } else if (res_data.graph_type === "doughnut") {
      setGraphType("doughnut");
      // Create an array of colors for the doughnut graph.
      const doughnutColors = randomColor({ count: num_data_points });
      const chart_obj = {
        labels: res_data.x,
        datasets: [
          {
            type: "doughnut",
            label: res_data.label,
            data: res_data.y,
            backgroundColor: doughnutColors,
          },
        ],
      };

      //Setting ticket data for the doughnut
      setTicketData(chart_obj);

      // Remove legend for doughnut graphs.
      char_opts.plugins.legend.display = false;
    }

    setGraphOptions(char_opts);
    setGraphStatData(res_data);
    setIsDataAvailable(true);
  };

  useEffect(() => {
    setIsDataAvailable(false);
    fetchData();
    setApiResponseError(false);
  }, [graphUrl]);

  let agentName = useRef("");
  const getDrillDownDetails = (event: any, chartref: any) => {
    // console.log("CURRENT GRAPH TYPE IS", graphType);

    if (graphType === "bar") {
      if (chartRef.current) {
        const element = getElementAtEvent(chartRef.current, event);
        const selectedBarIndex = element[0]?.index;
        agentName.current = ticketData.labels[selectedBarIndex];

        if (ticketData.labels.includes(ticketData.labels[selectedBarIndex])) {
          setIsOverlayVisible(true);
        }
      }
    } else {
      //element ->

      if (chartRef.current) {
        const clickEvent = getElementAtEvent(chartRef.current, event);
        // @ts-ignore
        const selectedIndex = clickEvent[0]?.element["$context"].dataIndex;
        const categoryName = ticketData.labels[selectedIndex];
        navigate(`${ROUTE.FEEDBACK}/${categoryName}`);
      }
    }
  };

  return (
    <>
      {apiResponseError ? (
        <span id="dashboard-graph-placeholder-text">
          Set a different time range
        </span>
      ) : (
        <span id="dashboard-graph">
          {!isDataAvailable ? (
            <div id="dashboard-graph-spinner">
              <ThreeDots
                height="40"
                width="40"
                radius="5"
                color="#fab46e"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                visible={true}
              />
            </div>
          ) : (
            <>
              {isOverlayVisible && (
                <OverlayModal
                  display="bar"
                  graphUrl={graphUrl}
                  agentName={agentName.current}
                  isVisible={isOverlayVisible}
                  changeOverlayCardState={changeOverlayCardState}
                />
              )}
              <div className="bar-chart">
                {bestAndWorstInsight.includes(graphUrl) ? (
                  <>
                    <BootstrapTable graphUrl={graphUrl} />
                  </>
                ) : (
                  <Bar
                    data={ticketData}
                    options={graphOptions}
                    ref={chartRef}
                    onClick={(event) => {
                      getDrillDownDetails(event, chartRef);
                    }}
                  />
                )}
              </div>
            </>
          )}
        </span>
      )}
    </>
  );
};

// Exports:
export default MiscGraph;
