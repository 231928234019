// Packages:
import { useCallback, useState } from "react";

import { useSelector } from "react-redux";

import axios from "axios";

import { getCurrenOrgID } from "../../redux/slices/orgInfoSlice";
import { getCurrentEndDate, getCurrentStartDate } from "../../redux/slices/timeRangeSlice";

import { AxiosHeadersInterface, WithIndex } from "../../constants/interfaces";

import { Col, Row } from "react-bootstrap";

import { ThreeDots } from  'react-loader-spinner';

import TopBar from "../../components/TopBar";
import AnalyzePanel from "./AnalyzePanel";
import NumHeatmap from "./AnalyzeViz/NumHeatmap";
import CatgHeatmap from "./AnalyzeViz/CatgHeatmap";
import VolBarGraph from "./AnalyzeViz/VolBarGraph";
import NumScatterPlot from "./AnalyzeViz/NumScatterPlot";

import { ANALYZE_DATA_URL } from "../../Endpoints";

import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend);



interface AnalysisDataInterface {
  num_fields_heatmap: [];
  num_fields_scatterplot: [];
  catg_fields_vol_bar_graph: [];
  catg_stat_heatmap: [];
}

interface HeatMapInputDataInterface {
  name: string;
  data: number[];
}

interface CatgHeatmapDataInterface {
  id: string;
  data: HeatMapInputDataInterface[];
  columns: string[];
  fields: string[];
  graph_type: string;
}

interface BarInputDataInterface {
  x: string[];
  y: number[];
}

interface VolBarDataInterface {
  id: string;
  data: BarInputDataInterface;
  fields: string[];
  graph_type: string;
}

interface ScatterPlotDataInterface {
  id: string;
  data: any[];
  fields: string[];
  graph_type: string;
  corr: number;
}

interface NumHeatmapInterface {
  id: string;
  data: HeatMapInputDataInterface[];
  fields: string[];
  graph_type: string;
}


interface AnalyzeInsightItemInterface {
  id: string;
  insight_str: string;
}

interface AnalyzeInsightInterface {
  num_fields_heatmap: AnalyzeInsightItemInterface[];
  catg_stat_heatmap: AnalyzeInsightItemInterface[];
}

// Functions:
const Analyze = () => {

  const currOrgID = useSelector(getCurrenOrgID);
  const startDate = useSelector(getCurrentStartDate);
  const endDate = useSelector(getCurrentEndDate);

  const emptyAnalysisDataObj : AnalysisDataInterface = {
    num_fields_heatmap: [],
    num_fields_scatterplot: [],
    catg_fields_vol_bar_graph: [],
    catg_stat_heatmap: []
  }
  const [analysisData, setAnalysisData] = useState(emptyAnalysisDataObj);

  const emptyAnalysisInsightObj : AnalyzeInsightInterface = {
    num_fields_heatmap: [],
    catg_stat_heatmap: []
  }
  const [analysisInsights, setAnalysisInsights] = useState(emptyAnalysisInsightObj);

  const [showLoadingSpinner, setShowLoadingSpinner] = useState<boolean>(false);

  const fetchAnalysisData = useCallback(async (fields: string[]) => {

    setShowLoadingSpinner(true);
    setAnalysisData(emptyAnalysisDataObj);
    setAnalysisInsights(emptyAnalysisInsightObj);

    try {
      const reqHeaders: WithIndex<AxiosHeadersInterface> = {}
      reqHeaders['X-API-Key'] = process.env.REACT_APP_API_KEY
      const res = await axios.get(ANALYZE_DATA_URL, {
        params: {
          org_id: currOrgID,
          fields: fields.join(","),
          start_date: startDate,
          end_date: endDate,
        },
        headers: reqHeaders,
      });

      setAnalysisData(res.data.analysis_data);

      setAnalysisInsights(res.data.insights);

    } catch (error) {
      console.error(error);
    }

    setShowLoadingSpinner(false);
  }, []);


  return (
    <>
      <TopBar />
      <div className="main-content-container">
        <span className="main-content-panel" >
          {(showLoadingSpinner) ? (
            <div style={{ marginLeft: "45%", marginTop: "20%" }} >
              <ThreeDots
                height="40"
                width="40"
                radius="5"
                color="#fab46e"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                visible={true}
              />
            </div>
          ) : (
            <Row xs={1} md={3} className="g-3">
              {(analysisData.num_fields_heatmap.length > 0) &&
                analysisData.num_fields_heatmap.map((chartData: NumHeatmapInterface) => {
                  return (
                    <Col key={chartData.id} className="analyze-content-column" >
                      <NumHeatmap chartData={chartData} />
                    </Col>
                  )
              })}
              {(analysisData.catg_stat_heatmap.length > 0) &&
                analysisData.catg_stat_heatmap.map((chartData: CatgHeatmapDataInterface) => {
                  return (
                    <Col key={chartData.id} className="analyze-content-column" >
                      <CatgHeatmap chartData={chartData} />
                    </Col>
                  )
              })}
              {(analysisData.catg_fields_vol_bar_graph.length > 0) &&
                analysisData.catg_fields_vol_bar_graph.map((chartData: VolBarDataInterface) => {
                  return (
                    <Col key={chartData.id} className="analyze-content-column" >
                      <VolBarGraph chartData={chartData} />
                    </Col>
                  )
              })}
              {(analysisData.num_fields_scatterplot.length > 0) &&
                analysisData.num_fields_scatterplot.map((chartData: ScatterPlotDataInterface) => {
                  return (
                    <Col key={chartData.id} className="analyze-content-column" >
                      <NumScatterPlot chartData={chartData} />
                    </Col>
                  )
              })}
            </Row>
          )}
        </span>
        <AnalyzePanel
          fetchAnalysisData={fetchAnalysisData}
          analysisInsights={analysisInsights}
        />
      </div>
    </>
  )
}


// Exports:
export default Analyze
