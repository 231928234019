import { useState, useEffect, useRef, MouseEvent } from 'react'

// React-bootstrap
import { Modal, Card, Spinner, Button } from 'react-bootstrap';

import axios from "axios"

import { useSelector } from 'react-redux';

import { BiExpandAlt } from 'react-icons/bi'
import { AiOutlineClose } from 'react-icons/ai'

import { BASE_INSIGHT_URL } from '../../../../Endpoints'

import { getCurrenOrgID } from '../../../../redux/slices/orgInfoSlice';
import { getCurrentStartDate, getCurrentEndDate } from '../../../../redux/slices/timeRangeSlice';

import { AxiosHeadersInterface, WithIndex } from '../../../../constants/interfaces';

// For graphs
import {
  Doughnut, 
  getDatasetAtEvent, 
  getElementAtEvent,  
} from 'react-chartjs-2';
import type { InteractionItem } from 'chart.js';
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
} from 'chart.js';

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip
);


const DoughnutGraph = ({
  graphUrl,
  handleGraphCardClose,
  handleGTMBtnClick,
  handleGraphItemClick
} : {
  graphUrl: string
  handleGraphCardClose: (url: string) => void
  handleGTMBtnClick: () => void
  handleGraphItemClick: (q: string) => void
}) => {

  const currOrgID = useSelector(getCurrenOrgID);
  const startDate = useSelector(getCurrentStartDate);
  const endDate = useSelector(getCurrentEndDate);

  const [ticketData, setTicketData] = useState<any>([])
  const [graphOptions, setGraphOptions] = useState<any>([]);
  const [isDataAvailable, setIsDataAvailable] = useState(false);

  const [modalShow, setModalShow] = useState<boolean>(false);

  const fetchData = async () => {
    try {
      const reqHeaders: WithIndex<AxiosHeadersInterface> = {}
      reqHeaders['X-API-Key'] = process.env.REACT_APP_API_KEY
      const res = await axios.get(BASE_INSIGHT_URL, {
        params: {
          q: graphUrl,
          org_id: currOrgID,
          start_date: startDate,
          end_date: endDate,
        },
        headers: reqHeaders,
      });
      const data = await res.data;
      getChartData(data);
    } catch (error) {
      console.error(error);
    }
  }

  const getChartData = (res_data: any) => {

    const chart_obj = {
        labels: res_data.x,
        datasets: [
          {
            // label: res_data.label,
            data: res_data.y,
            backgroundColor: ["#73b7d9", "#ed0c80", "#ab6b05", "#9edb58", "#e65f55",]
          }
        ]
      }
    setTicketData(chart_obj);

    const char_opts = {
      plugins: {
        title: {
          display: true,
          text: res_data.str
        },
        legend: {
          display: true,
          position: "bottom"
        },
      }
    };
    setGraphOptions(char_opts);

    setIsDataAvailable(true);
  };

  const cardGraphRef = useRef<any>(null);
  const onCardGraphClick = (event: MouseEvent<HTMLCanvasElement>) => {
    const { current: chart } = cardGraphRef;

    if (!chart) {
      return;
    }

    // Dataset related info
    const dataset: InteractionItem[] = getDatasetAtEvent(chart, event);
    if (!dataset.length) return;

    // const dsIndex = dataset[0].datasetIndex;

    // Element specific info
    const el : InteractionItem[] = getElementAtEvent(chart, event);
    if (!el.length) return;

    const { datasetIndex, index } = el[0];
    const el_label = ticketData.labels[index];
    // const el_val = ticketData.datasets[datasetIndex].data[index];

    // Call func in compare to show lower component.
    // TODO: Only needed for feedback graphs. Add condition.
    handleGraphItemClick(el_label)
  }

  useEffect(() => {
    setIsDataAvailable(false);
    fetchData();
  }, []);


  return (
    <Card >
      <Card.Header className="graph-card-header draggable-item" style={{ alignItems: "center"}} >
        <Button size="sm" onClick={handleGTMBtnClick} variant="secondary" style={{float: "left"}} >Generate Topic Model</Button>
        <AiOutlineClose className="graph-close-btn compare-graph-card-btn" onClick={() => handleGraphCardClose(graphUrl)} />
        {(Object.keys(ticketData).length > 0) &&
          <BiExpandAlt 
            className="expand-item-btn compare-graph-card-btn" 
            onClick={() => setModalShow(true)}
            title="Expand Graph"
          />
        }
      </Card.Header>
      <div className="grahpviz-wrapper" >
        {(!isDataAvailable) ? (<Spinner style={{marginTop: "5rem"}} animation="border" variant="secondary" />)
          : (<Doughnut
              data={ticketData}
              options={graphOptions}
              style={{maxHeight: "13rem", padding: "0.5rem"}}
              ref={cardGraphRef}
              onClick={onCardGraphClick}
            />
          )
        }
        <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered
          show={modalShow}
          onHide={() => setModalShow(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Zoomed-in View
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ minHeight: "15rem" }} >
            <Doughnut
              style={{maxHeight: "25rem"}}
              data={ticketData}
              options={graphOptions}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setModalShow(false)}>Close</Button>
          </Modal.Footer>
        </Modal>
      </div>
    </Card>
  )
}

// Exports:
export default DoughnutGraph
