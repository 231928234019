import React, { useState, useEffect } from "react";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";

import { GET_FEEDBACK_WORDCLOUD } from "../../../../../Endpoints";
import { getCurrenOrgID } from "../../../../../redux/slices/orgInfoSlice";
import {
  getCurrentEndDate,
  getCurrentStartDate,
} from "../../../../../redux/slices/timeRangeSlice";
import { EmerTopicWordCloud as WordCloud } from "../../../../EmergingTopic/EmerTopicWordCloud";
import styled from "styled-components";
import { fadeIn } from "../../../../../styles/animations";
import { AxiosHeadersInterface, WithIndex } from "../../../../../constants/interfaces";

const Title = styled.div<{ transitionFactor: number }>`
  color: #b2b2b2;
  margin-top: 0.9rem;
  font-weight: 700;
  font-size: 1.3rem;
  animation: ${(props) => fadeIn(props.transitionFactor + 3)}
    ${(props) => 0.25 * props.transitionFactor}s ease;
  user-select: none;
`;

interface Props {
  feedback: string | undefined;
}

const FeedbackWordCloud = (props: Props) => {
  const [wordCloudData, setWordCloudData] = useState<any>([]);
  const [isWordCloudDataAvailable, setIsWordCloudDataAvailable] =
    useState(false);
  const [apiError, setApiError] = useState(false);

  const currOrgID = useSelector(getCurrenOrgID);
  const startDate = useSelector(getCurrentStartDate);
  const endDate = useSelector(getCurrentEndDate);

  const fetchData = async () => {
    try {
      const reqHeaders: WithIndex<AxiosHeadersInterface> = {};
      reqHeaders["X-API-Key"] = process.env.REACT_APP_API_KEY;
      const res = await axios.get(GET_FEEDBACK_WORDCLOUD, {
        params: {
          org_id: currOrgID,
          start_date: startDate,
          end_date: endDate,
          name: props.feedback,
        },
        headers: reqHeaders,
      });

      const data = await res.data;
      setWordCloudData(data);
      setIsWordCloudDataAvailable(true);
    } catch (err) {
      console.log(err);
      setApiError(true);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {apiError ? (
        <span id="dashboard-graph-placeholder-text">
          Something went wrong, refresh the page and try again!
        </span>
      ) : (
        <>
          {!isWordCloudDataAvailable ? (
            <div className="util-center">
              <Spinner animation="border" variant="secondary" />
            </div>
          ) : (
            <div>
              <Title
                style={{ marginTop: 0, marginBottom: "0.5rem" }}
                transitionFactor={1}
              >
                Wordcloud : {props.feedback} Feedback
              </Title>
              <WordCloud
                wordCloudData={wordCloudData}
                setWordCloudData={setWordCloudData}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default FeedbackWordCloud;
