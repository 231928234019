import { Card } from 'react-bootstrap';

// For graphs
import {Bar} from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

type BarInputDataInterface = {
  x: string[];
  y: number[];
}

interface VolBarDataInterface {
  id: string;
  data: BarInputDataInterface;
  fields: string[];
  graph_type: string;
}


const VolBarGraph = ({
  chartData,
} : {
  chartData: VolBarDataInterface
}) => {

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: false,
        // text: 'Chart.js Bar Chart',
      },
    },
  };

  const labels = chartData.data.x;

  const data = {
    labels,
    datasets: [
      {
        label: 'Ticket volume per ' + (chartData.fields[0].replaceAll('_', ' ')).toUpperCase(),
        data: chartData.data.y,
        backgroundColor: "#73b7d9",
      },
    ],
  };

  return (
    <Card className='analyze-graph-card' >
      <div className='analyze-graph-card-content' >
        <Bar
          data={data}
          options={options}
          style={{padding: "1rem"}}
        />
      </div>
    </Card>
  )
}

// Exports:
export default VolBarGraph
