// Packages:
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import axios from 'axios';

import { Button, Form, Modal } from 'react-bootstrap';

import { getLoggedInUser } from '../../../../redux/slices/authSlice';
import { getCurrenOrgID } from '../../../../redux/slices/orgInfoSlice';

import { AxiosHeadersInterface, WithIndex } from '../../../../constants/interfaces';
import { EMER_TOPICS_SUB, EMER_TOPICS_UNSUB } from '../../../../Endpoints';


// Functions:
const SubscriptionModal = ({
  modalShow,
  setModalShow,
  isSubscribed,
  subscriptionInfo
} : {
  modalShow: boolean
  setModalShow: React.Dispatch<React.SetStateAction<boolean>>
  isSubscribed: boolean
  subscriptionInfo: any
}) => {

  const currOrgID = useSelector(getCurrenOrgID);
  const logInStatus = useSelector(getLoggedInUser);

  const [isNotificationMethodSelected, setIsNotificationMethodSelected] = useState<boolean>(false);

  const [smsFeedback, setSmsFeedback] = useState<boolean>(false);
  const [slackFeedback, setSlackFeedback] = useState<boolean>(false);

  const [notificationFrequency, setNotificationFrequency] = useState<string>('');

  useEffect(() => {

    if (isSubscribed) {
      setIsNotificationMethodSelected(true)
    } else {
      setIsNotificationMethodSelected(false)
    }

    if (subscriptionInfo.feedback?.sms) {
      setSmsFeedback(true)
    } else {
      setSmsFeedback(false)
    }

    if (subscriptionInfo.feedback?.slack) {
      setSlackFeedback(true)
    } else {
      setSlackFeedback(false)
    }

    if (subscriptionInfo.frequency !== undefined) {
      setNotificationFrequency(subscriptionInfo.frequency)
    } else {
      setNotificationFrequency('')
    }

  }, [isSubscribed, subscriptionInfo]);


  // This function is called when the modal is closed.
  const cleanupOnModalClose = () => {
    setModalShow(false)

    // Set these back to the default values.
    setIsNotificationMethodSelected(false);
    setNotificationFrequency('');
    setSmsFeedback(false);
    setSlackFeedback(false);
  }


  const subscribeUser = async () => {
    // POST request to subscribe user
    try {
      const reqHeaders: WithIndex<AxiosHeadersInterface> = {}
      reqHeaders['X-API-Key'] = process.env.REACT_APP_API_KEY

      await axios({
        method: 'POST',
        url: EMER_TOPICS_SUB,
        data: {
          org_id: currOrgID,
          email: logInStatus.attributes.email,
          is_Subscribed: isSubscribed,
          frequency: notificationFrequency,
          feedback: {
            sms: smsFeedback,
            slack: slackFeedback
          },
        },
        headers: reqHeaders,
      });

      cleanupOnModalClose();
      alert("Subscribed!");

    } catch (error) {
      console.error(error);
      alert("Failed to subscribe! Try again.");
    }
  }


  const unSubscribeUser = async () => {
    // POST request to un-subscribe user
    try {
      const reqHeaders: WithIndex<AxiosHeadersInterface> = {}
      reqHeaders['X-API-Key'] = process.env.REACT_APP_API_KEY

      await axios({
        method: 'POST',
        url: EMER_TOPICS_UNSUB,
        data: {
          org_id: currOrgID,
          email: logInStatus.attributes.email,
        },
        headers: reqHeaders,
      });


      cleanupOnModalClose();
      alert("Unsubscribed!");

    } catch (error) {
      console.error(error);
      alert("Failed to unsubscribe! Try again.");
    }
  }


  // Return:
  return (
    <>
      <Modal size="sm" aria-labelledby="contained-modal-title-vcenter" centered
        show={modalShow}
        onHide={cleanupOnModalClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Subscription settings
          </Modal.Title>
        </Modal.Header>
        <Modal.Body id='subscription-modal-body' >
          <Form id='subscription-form'>
            <Form.Group>
              <Form.Text>
                Select Notification method:
              </Form.Text>
              <Form.Check
                label="Email Notifications"
                name="email-notif"
                id="email-notif"
                className='subscription-form-checkbox-item'
                defaultChecked={isSubscribed}
                onChange={(e: any) => setIsNotificationMethodSelected(e.target.checked)}
              />
            </Form.Group>
            <hr />
            <Form.Group>
              <Form.Text>
                What other notification methods would you like in the future:
              </Form.Text>
              <Form.Check
                label="SMS Notifications"
                name="notif-poll"
                id="notif-poll-sms"
                className='subscription-form-checkbox-item'
                defaultChecked={smsFeedback}
                onChange={(e: any) => setSmsFeedback(e.target.checked)}
              />
              <Form.Check
                label="Slack Notifications"
                name="notif-poll"
                id="notif-poll-slack"
                className='subscription-form-checkbox-item'
                defaultChecked={slackFeedback}
                onChange={(e: any) => setSlackFeedback(e.target.checked)}
              />
            </Form.Group>
            <hr />
            <Form.Group>
              <Form.Text>
                Select Notification frequency:
              </Form.Text>
              <Form.Check
                label="Instant Notifications"
                name="freq-checkbox"
                id="freq-checkbox-hourly"
                type='radio'
                className='subscription-form-checkbox-item'
                defaultChecked={(subscriptionInfo.frequency === 'hourly')}
                onChange={() =>setNotificationFrequency('hourly')}
              />
              <Form.Check
                label="Daily Notifications"
                name="freq-checkbox"
                id="freq-checkbox-daily"
                type='radio'
                className='subscription-form-checkbox-item'
                defaultChecked={(subscriptionInfo.frequency === 'daily')}
                onChange={() =>setNotificationFrequency('daily')}
              />
              <Form.Check
                label="Weekly Notifications"
                name="freq-checkbox"
                id="freq-checkbox-weekly"
                type='radio'
                className='subscription-form-checkbox-item'
                defaultChecked={(subscriptionInfo.frequency === 'weekly')}
                onChange={() =>setNotificationFrequency('weekly')}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={subscribeUser}
            disabled={!isNotificationMethodSelected || (notificationFrequency.length === 0)}
          >
            Subscribe
          </Button>
          {(isSubscribed) &&
            <Button variant="danger" onClick={unSubscribeUser} >Unsubscribe</Button>
          }
        </Modal.Footer>
      </Modal>
    </>
  )
}


// Exports:
export default SubscriptionModal
