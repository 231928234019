// Packages:
import { useState } from 'react'

import styled from 'styled-components'
import Color from 'color'

// Styles:
import { fadeIn } from '../../../styles/animations'
import MiscGraph from './MiscGraph'


const Wrapper = styled.div`
  position: relative;
  min-width: 40rem;
  min-height: 35rem;
  max-width: 95%;
  padding: 1rem;
  color: ${ Color('#FFFFFF').darken(0.6).hex() };
  background-color: ${ Color('#FFFFFF').darken(0.08).hex() };
  border: 1px solid ${ Color('#FFFFFF').darken(0.24).hex() };
  border-radius: 7.5px;
  animation : ${ fadeIn(2) } 0.5s ease;
  display: flex;
  justify-content: center;
  align-items: center;
`


// Functions:
const DataViz = ({
  graphUrl
}: {
  graphUrl: string
}) => {

  const [graphStatData, setGraphStatData] = useState<any>();

  return (
    <Wrapper>
      <MiscGraph graphUrl={graphUrl} setGraphStatData={setGraphStatData} />
      
      <div className="bottom-bar">
        {(graphStatData) && 
          <span style={{ margin: "2rem"}} >
            <span>Mean: <strong>{graphStatData.avg}</strong></span>
            <span>  |  Standard Deviation: <strong>{graphStatData.std}</strong></span>
            <span>  |  UCL: <strong>{graphStatData.ucl}</strong></span>
            <span>  |  LCL: <strong>{Math.max(graphStatData.lcl, 0)}</strong></span>
            {(graphStatData.total_ticket) && <span>  |  Total Tickets: <strong>{graphStatData.total_ticket}</strong></span>}
          </span>}
      </div>
    </Wrapper>
  )
}


// Exports:
export default DataViz
