export const BASE_INSIGHT_URL =
  `https://${process.env.REACT_APP_DOMAIN_KEY}.execute-api.us-east-1.amazonaws.com/api/insights`;
export const TOPIC_MODEL_URL =
  `https://${process.env.REACT_APP_DOMAIN_KEY}.execute-api.us-east-1.amazonaws.com/api/topic-model`;
export const LIKE_DISLIKE_URL =
  `https://${process.env.REACT_APP_DOMAIN_KEY}.execute-api.us-east-1.amazonaws.com/api/events`;
export const FEEDBACK_TRENDS_URL =
  `https://${process.env.REACT_APP_DOMAIN_KEY}.execute-api.us-east-1.amazonaws.com/api/feedback`;
export const ANALYZE_DATA_URL =
  `https://${process.env.REACT_APP_DOMAIN_KEY}.execute-api.us-east-1.amazonaws.com/api/analyze`;

export const EMER_TOPICS_INSIGHTS =
  `https://${process.env.REACT_APP_DOMAIN_KEY}.execute-api.us-east-1.amazonaws.com/api/allinsightstrings`;
export const EMER_TOPICS_GRAPHS =
  `https://${process.env.REACT_APP_DOMAIN_KEY}.execute-api.us-east-1.amazonaws.com/api/particularinsight_graph`;
export const EMER_TOPICS_HIST_DATA =
  `https://${process.env.REACT_APP_DOMAIN_KEY}.execute-api.us-east-1.amazonaws.com/api/topichistory`;
export const EMER_TOPICS_WC_DATA =
  `https://${process.env.REACT_APP_DOMAIN_KEY}.execute-api.us-east-1.amazonaws.com/api/wordcloud`;

export const EMER_TOPICS_SUB_STATUS =
  `https://${process.env.REACT_APP_DOMAIN_KEY}.execute-api.us-east-1.amazonaws.com/api/subscription_status`;
export const EMER_TOPICS_SUB =
  `https://${process.env.REACT_APP_DOMAIN_KEY}.execute-api.us-east-1.amazonaws.com/api/subscribe`;
export const EMER_TOPICS_UNSUB =
  `https://${process.env.REACT_APP_DOMAIN_KEY}.execute-api.us-east-1.amazonaws.com/api/unsubscribe`;

export const GET_ORG_DATA_URL =
  `https://${process.env.REACT_APP_FASTTRACK_KEY}.execute-api.us-east-1.amazonaws.com/api/get-organisation-data`;

export const GET_DRILLDOWN_VIEW_DETAILS =
  `https://${process.env.REACT_APP_DOMAIN_KEY}.execute-api.us-east-1.amazonaws.com/api/drilldown`;

export const GET_FEEDBACK_DETAILS =
  `https://${process.env.REACT_APP_DOMAIN_KEY}.execute-api.us-east-1.amazonaws.com/api/drilldown`;

export const GET_FEEDBACK_WORDCLOUD =
  `https://${process.env.REACT_APP_DOMAIN_KEY}.execute-api.us-east-1.amazonaws.com/api/drilldownWordcloud`;
