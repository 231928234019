// Packages:
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Amplify from 'aws-amplify';
import axios from 'axios';

import { addDays, parseISO } from 'date-fns'

import styled from 'styled-components'
import Color from 'color'
import COLOR from '../../constants/colors';

import { Button, Form } from 'react-bootstrap';

import { getCurrentOrgName, setCurrentOrgInfo } from '../../redux/slices/orgInfoSlice';
import { useAuth } from '../../utils/Auth';

import logo from '../../assets/readyly-xl.png'

import { GET_ORG_DATA_URL } from '../../Endpoints';

import { AxiosHeadersInterface, WithIndex } from '../../constants/interfaces';
import { setEndDate, setStartDate } from '../../redux/slices/timeRangeSlice';


const Wrapper = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100vw - 4rem);
  height: calc(100vh - 4rem);
  padding: 2rem;
  background: ${Color(COLOR.SECONDARY).hex()};
`


const Login = () => {

  const auth = useAuth()
  const dispatch = useDispatch();
  
  const currentOrgName = useSelector(getCurrentOrgName);

  const [subDomain, setSubDomain] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [isOrgAvailable, setIsOrgAvailable] = useState<boolean>(false);

  const fetchUserPoolInfo = async (subdomain: string) => {
    try {
      // Fetch the userpool data for this org.
      const reqHeaders: WithIndex<AxiosHeadersInterface> = {}
      reqHeaders['X-API-Key'] = process.env.REACT_APP_ORG_DATA_API_KEY
      const res = await axios({
        method: 'get',
        url: GET_ORG_DATA_URL,
        params: {
          subdomain: subdomain,
        },
        headers: reqHeaders,
      });
      const content = await res.data;

      const currOrgData = {
        orgID: content['org_data']['_id'],
        orgName: content['org_data']['title'],
        orgUserPoolInfo: {
          region: content['org_data']['cognito']['region'],
          userPoolId: content['org_data']['cognito']['userpool_id'],
          userPoolWebClientId: content['org_data']['cognito']['client_id']
        }
      };
      // Add the org &  user pool info to state
      dispatch(setCurrentOrgInfo(currOrgData))

      Amplify.configure({
        Auth: currOrgData.orgUserPoolInfo
      });

    } catch (error) {
      console.error(error);
    }
  }

  const onSignInSubmit = async (event: any) => {
    event.preventDefault();
    auth.login(email, password);

    // Set time range to last 7 days.
    const currDate = new Date();
    const defaultStartDate = addDays(parseISO(currDate.toISOString()), -7);

    dispatch(setStartDate(defaultStartDate));
    dispatch(setEndDate(currDate));
  };

  const onSubDomainSubmit = async (event: any) => {
    event.preventDefault();
    if (subDomain) {
      fetchUserPoolInfo(subDomain.toLowerCase());
    }
  }

  useEffect(() => {
    // We set this deault string in the corresponding redux slice.
    if (currentOrgName !== 'No organization selected') {
      setIsOrgAvailable(true)
    }
  }, [currentOrgName])

  return (
    <Wrapper>
      <Form id="login-form" >

        <div id="login-form-logo-container">
            <img src={logo} alt="" />
        </div>

        <Form.Group className="mb-3" controlId="loginFormOrgSelect">
          <Form.Control 
            type="subdomain" 
            placeholder="Organization" 
            onChange={(event: any) => setSubDomain(event.target.value)} 
          />
          <Form.Text className="text-muted" id="login-form-org-name" >
            {currentOrgName}
          </Form.Text>
        </Form.Group>

        <Button 
          variant="primary" 
          type="submit"
          onClick={onSubDomainSubmit}
          className="login-form-btn"
        >
          Submit
        </Button>

        <hr style={{width: "110%"}} />

        <Form.Group className="mb-4" controlId="loginFormEmail" >
          <Form.Control 
            type="email" 
            placeholder="Email" 
            onChange={(event: any) => setEmail(event.target.value)}
            disabled={!isOrgAvailable}
            style={{ marginTop: "2rem"}}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="loginFormPassword" >
          <Form.Control 
            type="password" 
            placeholder="Password" 
            onChange={(event: any) => setPassword(event.target.value)}
            disabled={!isOrgAvailable}
          />
        </Form.Group>

        <Button 
          variant="primary" 
          type="submit"
          onClick={onSignInSubmit}
          disabled={!isOrgAvailable}
          className="login-form-btn"
        >
          Sign in
        </Button>

      </Form>
    </Wrapper>
  );
};

// Exports:
export default Login
