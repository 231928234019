import { Card } from "react-bootstrap";

// For graphs
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";


interface HeatMapInputDataInterface {
  name: string;
  data: number[];
}

interface NumHeatmapInterface {
  id: string;
  data: HeatMapInputDataInterface[];
  fields: string[];
  graph_type: string;
}


const NumHeatmap = ({
  chartData,
} : {
  chartData: NumHeatmapInterface
}) => {

  const chartSeries = chartData.data;

  const chartOpts: ApexOptions = {
    chart: {
      toolbar: {
        show: false,
      }
    },
    dataLabels: {
      enabled: false,
      style: {
        colors: ['#FFFFFF']
      }
    },
    xaxis: {
      type: 'category',
      categories: chartData.fields,
    },
    tooltip: {
      enabled: true,
    },
    colors: ["#000000"],
  };


  return (
    <Card className='analyze-graph-card' >
      <div>
        <Chart
          type="heatmap"
          series={chartSeries}
          options={chartOpts}
        />
      </div>
    </Card>
  )
}

// Exports:
export default NumHeatmap
